import styled, { css } from 'styled-components'

export const Wrapper = styled.section`
  ${({ theme }) => css`

  .cookie-notice {
    position: fixed;
    bottom: 20px;
    left: 50%; 
    transform: translateX(-50%);
    padding: 10px;
    background-color: #fff; 
    display: flex;
    flex-direction: column; 
    align-items: center;
    max-width: 95%; 
    width: 100%;
    padding: 30px;
    margin: 0 auto; 
    font-size: 24px; 
    font-family: 'Nunito', sans-serif; 
    color: #633189;
    z-index: 9999;
    overflow: hidden;
  }

  .paragrafos {
    text-align: center;
    margin: 30px
  }

  .paragrafos p {
    margin: -10px 0; 
}

  .cookie-notice::before {
    content: "";
    width: 100%;
    height: 20px; 
    background-color: #46AABF; 
    position: absolute;
    top: 0;
    left: 0;
  }

  .botoes {
    display: flex;
    gap: 40px; 
  }

  .botoes button {
    border: none; 
    background: none; 
    padding: 5px 10px; 
    font-size: 16px; 
    font-family: 'Nunito', sans-serif; 
    color: #633189; 
    cursor: pointer; 
    outline: none; 
  }

  .botoes .botao-politica {
    text-decoration: underline; 
    font-size: 24px; 
    font-weight: bold;
  }

  .botoes .botao-aceitar {
    background-color: #633189; 
    color: #ffffff; 
    border: 2px solid #633189; 
    border-radius: 15px; 
    padding: 10px 20px; 
  }

  .botoes button:hover {
    background-color: #ffffff;
    color: #633189; 
  }

  }`
}`
