import React, { createContext, SetStateAction, useEffect, useRef, useState } from 'react'

type ContextProps = {
  aboutComponentRef: React.RefObject<HTMLDivElement>;
  frameComponentRef: React.RefObject<HTMLDivElement>;
  framePrati: React.RefObject<HTMLDivElement>;
  frameFinanceira: React.RefObject<HTMLDivElement>;
  ourProjectComponentRef: React.RefObject<HTMLDivElement>;
  servicesComponentRef: React.RefObject<HTMLDivElement>;
  projectsComponentRef: React.RefObject<HTMLDivElement>;
  contatoComponentRef: React.RefObject<HTMLDivElement>;
  controllerScroll: boolean;
  setControllerScroll: React.Dispatch<SetStateAction<boolean>>;
  openPoliticaPrivacidade: boolean;
  setOpenPoliticaPrivacidade: (openPoliticaPrivacidade: boolean) => void
  openCookiesModal: boolean;
  setOpenCookiesModal: (openCookiesModal: boolean) => void
  openComponentProject: boolean;
  setOpenComponentProject: React.Dispatch<SetStateAction<boolean>>;
  stateControllerComponentProject: boolean;
  setControllerComponentProject: React.Dispatch<SetStateAction<boolean>>;
  openOurProjectComponent:boolean;
  setOurProjectComponent: React.Dispatch<SetStateAction<boolean>>;
  positionToMoveScroll: number;
  setPositionToMoveScroll: React.Dispatch<SetStateAction<number>>;
  openComponentProjectPrati: boolean;
  setOpenComponentProjectPrati: React.Dispatch<SetStateAction<boolean>>;
  openComponentProjectFinanceira: boolean;
  setOpenComponentProjectFinanceira: React.Dispatch<SetStateAction<boolean>>;
  width: number;
  setWidth: React.Dispatch<SetStateAction<number>>;
  mobile: boolean;
  setMobile: React.Dispatch<SetStateAction<boolean>>;
  cookieComponentRef: React.RefObject<HTMLDivElement>;
  PoliticaPrivacidadeModal: React.RefObject<HTMLDivElement>;

  moveComponentProject(id: number): void
}

export const GlobalContext = createContext({} as ContextProps)

type GlobalStorageProps = {
  children: React.ReactNode;
}

export const GlobalStorage = ({ children }: GlobalStorageProps) => {
  const aboutComponentRef = useRef<HTMLDivElement>(null)
  const frameComponentRef = useRef<HTMLDivElement>(null)
  const framePrati = useRef<HTMLDivElement>(null)
  const frameFinanceira = useRef<HTMLDivElement>(null)
  const ourProjectComponentRef = useRef<HTMLDivElement>(null)
  const servicesComponentRef = useRef<HTMLDivElement>(null)
  const projectsComponentRef = useRef<HTMLDivElement>(null)
  const contatoComponentRef = useRef<HTMLDivElement>(null)
  const cookieComponentRef = useRef<HTMLDivElement>(null)
  const PoliticaPrivacidadeModal = useRef<HTMLDivElement>(null)

  const [controllerScroll, setControllerScroll] = useState(false)
  const [openCookiesModal, setOpenCookiesModal] = useState(false)
  const [openPoliticaPrivacidade, setOpenPoliticaPrivacidade] = useState(false)
  const [openComponentProject, setOpenComponentProject] = useState(false)
  const [openComponentProjectPrati, setOpenComponentProjectPrati] = useState(false)
  const [openComponentProjectFinanceira, setOpenComponentProjectFinanceira] = useState(false)
  const [openOurProjectComponent, setOurProjectComponent] = useState(false)
  const [stateControllerComponentProject, setControllerComponentProject] = useState(false)
  const [positionToMoveScroll, setPositionToMoveScroll] = useState(0)
  const [width, setWidth] = useState(window.screen.width)
  const [mobile, setMobile] = useState(false)

  useEffect(() => {
    const cookiesAccpeted = localStorage.getItem('acceptedCookies')
    if (!!cookiesAccpeted && cookiesAccpeted === 'true') {
      setOpenCookiesModal(false)
    } else {
      setOpenCookiesModal(true)
    }
    if (window.screen.width <= 599.9) {
      setMobile(true)
    } else {
      setMobile(false)
    }

    window.addEventListener('resize', () => {
      setWidth(window.screen.width)
      if (window.screen.width <= 599.9) {
        setMobile(true)
      } else {
        setMobile(false)
      }
    })
  })

  useEffect(() => {
    if (positionToMoveScroll === 1 && projectsComponentRef.current) {
      window.scroll({
        top: projectsComponentRef.current.offsetTop,
        behavior: 'smooth'
      })
      setPositionToMoveScroll(0)
    }

    if (positionToMoveScroll === 2 && servicesComponentRef.current) {
      window.scroll({
        top: servicesComponentRef.current.offsetTop,
        behavior: 'smooth'
      })
      setPositionToMoveScroll(0)
    }

    if (positionToMoveScroll === 3 && contatoComponentRef.current) {
      window.scroll({
        top: contatoComponentRef.current.offsetTop,
        behavior: 'smooth'
      })
      setPositionToMoveScroll(0)
    }
  }, [positionToMoveScroll, servicesComponentRef, contatoComponentRef, projectsComponentRef])

  function moveComponentProject (id: number) {
    setOpenComponentProject(false)
    setOpenComponentProjectFinanceira(false)
    setOpenComponentProjectPrati(false)
    setOurProjectComponent(false)

    if (id === 1) {
      setOpenComponentProjectFinanceira(true)
    }
    if (id === 2) {
      setOpenComponentProjectPrati(true)
    }

    if (id === 3) {
      setOpenComponentProject(true)
    }
    setControllerComponentProject(true)
  }

  return (
    <GlobalContext.Provider value={{
      aboutComponentRef,
      frameComponentRef,
      framePrati,
      frameFinanceira,
      ourProjectComponentRef,
      servicesComponentRef,
      projectsComponentRef,
      contatoComponentRef,
      controllerScroll,
      setControllerScroll,
      openCookiesModal,
      setOpenCookiesModal,
      openPoliticaPrivacidade,
      setOpenPoliticaPrivacidade,
      openComponentProject,
      setOpenComponentProject,
      stateControllerComponentProject,
      setControllerComponentProject,
      openOurProjectComponent,
      setOurProjectComponent,
      positionToMoveScroll,
      setPositionToMoveScroll,
      openComponentProjectPrati,
      setOpenComponentProjectPrati,
      openComponentProjectFinanceira,
      setOpenComponentProjectFinanceira,
      width,
      setWidth,
      mobile,
      setMobile,
      moveComponentProject,
      cookieComponentRef,
      PoliticaPrivacidadeModal
    }}>
      {children}
    </GlobalContext.Provider>
  )
}
