import styled, { css } from 'styled-components'

type WrapperProps = {
  firstBorderWidthAndHeight: string;
  secoundBorderWidthAndHeight: string;
  centerWidthAndHeight: string;
  marginTop: string;
  colorSecoundaryBorder: string;
  firstBorderSize: string;
  secoundBorderSize: string;
  cursor?: string;
  backgroundColorCenterContainer?: string;
}

export const Wrapper = styled.div<WrapperProps>`
  ${({
    theme,
    firstBorderSize,
    centerWidthAndHeight,
    colorSecoundaryBorder,
    firstBorderWidthAndHeight,
    marginTop,
    secoundBorderSize,
    secoundBorderWidthAndHeight,
    cursor,
    backgroundColorCenterContainer
  }) => css`
    width: max-content;
    height: max-content;
    display: flex;
    justify-content: flex-end;

    .borderGray {
      width: ${firstBorderWidthAndHeight && firstBorderWidthAndHeight};
      height: ${firstBorderWidthAndHeight && firstBorderWidthAndHeight};
      margin-top: ${marginTop && marginTop};
      border: ${firstBorderSize && `${firstBorderSize} solid ${theme.colors.gray}`};
      border-radius: 50%;
      cursor: ${cursor && cursor};
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .borderBlue {
      width: ${secoundBorderWidthAndHeight && secoundBorderWidthAndHeight};
      height: ${secoundBorderWidthAndHeight && secoundBorderWidthAndHeight};
      border: ${secoundBorderSize ? `${secoundBorderSize} solid ${colorSecoundaryBorder}` : colorSecoundaryBorder ?? ''};
      border-radius: 50%;
      cursor: ${cursor && cursor};
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .iconOfMore {
      width: ${centerWidthAndHeight && centerWidthAndHeight};
      height: ${centerWidthAndHeight && centerWidthAndHeight};
      cursor: ${cursor && cursor};
      background-color: ${backgroundColorCenterContainer ?? theme.colors.purple};
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    @media (min-width: 600px) and (max-width: 1750.9px) {
      .borderGray {
        width: 5vw;
        height: 5vw;
        margin-top: ${marginTop && marginTop};
        border: ${firstBorderSize && `${firstBorderSize} solid ${theme.colors.gray}`};
      }

      .borderBlue {
        width: 4vw;
        height: 4vw;
        border: ${secoundBorderSize ? `0.2vw solid ${colorSecoundaryBorder}` : colorSecoundaryBorder ?? ''};
      }

      .iconOfMore {
        width: 38px;
        height: 38px;
      }
    }

    @media (max-width: 599.9px) {
      .borderGray {
        width: 12vw;
        height: 12vw;
        margin-top: ${marginTop && marginTop};
        border: ${firstBorderSize && `${firstBorderSize} solid ${theme.colors.gray}`};
      }
      .borderBlue {
        width: 10vw;
        height: 10vw;
        border: ${secoundBorderSize ? `0.4vw solid ${colorSecoundaryBorder}` : colorSecoundaryBorder ?? ''};
      }

      .iconOfMore {
        width: 6vw;
        height: 6vw;
      }
    }
  `}
`
