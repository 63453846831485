import React, { useContext } from 'react'
import * as S from './styles'

import { GlobalContext } from '../../context/GlobalContext'

export const PoliticaPrivacidadeModal: React.FC = () => {
  const {
    PoliticaPrivacidadeModal,
    setOpenPoliticaPrivacidade
  } = useContext(GlobalContext)

  return (
    <S.Wrapper ref={PoliticaPrivacidadeModal}>
      <div id="privacyModal" className="modal">
        <div className="modal-content">
          <div className='closeWrapper'>
            <button className="close" onClick={() => setOpenPoliticaPrivacidade(false)}>
              <span id="closeModal" >&times;</span>
            </button>
          </div>

          <h1>Política de Privacidade</h1>
          <h4>Última Atualização: 31/10/2023</h4>

          <h2>1. Objetivo</h2>
          <p>A atual Política de Privacidade tem a intenção de fornecer orientações gerais sobre a aplicação da Lei Geral de Proteção de Dados - LGPD (Lei nº 13.709, de 14 de agosto de 2018), no contexto de atuação, serviços e formato de trabalho de todos os profissionais da T-UP Consultoria e Soluções em TI. Nela, esclarecemos quais informações são coletadas, de que maneira e com qual finalidade as utilizamos, para quem as compartilhamos e como garantimos a segurança da privacidade dos usuários (clientes e visitantes do site) ao utilizarem nossos serviços.</p>

          <h2>2. Informações que Coletamos</h2>
          <h3>2.1. Informações Pessoais</h3>
          <p>A T-UP Consultoria e Soluções em TI coleta informações pessoais que os usuários nos fornecem diretamente como: nome, endereço de e-mail, número de telefone, quando entram em contato conosco, acrescentando informações de pagamento quando utilizados nossos serviços.
            É importante frisar que não coletamos dados sensíveis de nossos usuários, como: origem racial e étnica, opiniões políticas, convicções religiosas ou filosóficas, informações genéticas, biométricas, de vida sexual, gênero ou saúde.</p>

          <h3>2.2. Informações de Uso</h3>
          <p>Podemos coletar informações de como o usuário utiliza nossos serviços, como endereço IP, tipo de dispositivo, navegador, páginas visitadas, data e hora de acesso, entre outras informações de uso.</p>

          <h3>2.3. Informações de Terceiros</h3>
          <p>Podemos receber informações de terceiros, como parceiros de negócios, provedores de serviços e redes de publicidade.</p>

          <h2>3. Como Usamos as Informações que Coletamos</h2>
          <h3>3.1. Prestação de Serviços</h3>
          <p>Utilizamos as informações pessoais coletadas a fim de oferecer nossos serviços de consultoria de TI e desenvolvimento de aplicativos móveis, incluindo comunicações relacionadas, faturamento, suporte e atendimento ao cliente</p>

          <h3>3.2. Melhoria de Serviços</h3>
          <p>Todas as informações são utilizadas de forma conjunta, o que nos permite melhorias na qualidade dos serviços oferecidos, desenvolvimento de novos recursos e realização de análises de negócios, garantindo assim, um atendimento sempre eficaz.</p>

          <h3>3.3. Marketing e Comunicações</h3>
          <p>De acordo com as informações coletadas e com a permissão do usuário, podemos enviar comunicações de marketing direcionado sobre nossos serviços e produtos. O usuário pode optar por não receber essas comunicações a qualquer momento.</p>

          <h2>4. Compartilhamento de Informações</h2>
          <h3>4.1. Terceiros</h3>
          <p>Poderemos compartilhar informações com terceiros, como prestadores de serviços, parceiros comerciais e órgãos reguladores quando necessário, para prestação de serviços ou cumprimento de obrigações legais, sempre respeitando as medidas adequadas de proteção dos dados</p>

          <h3>4.2. Marketing de Terceiros</h3>
          <p>Com o consentimento do usuário, poderemos compartilhar as informações coletadas com terceiros para marketing direcionado e outros fins específicos.</p>

          <h2>5. Responsáveis pelo Tratamento de Dados Pessoais</h2>
          <h3>5.1. O Controlador</h3>
          <p>De acordo com a LGPD, a T-UP Consultoria e Soluções em TI é reconhecida como o Controlador, pois a ela compete as decisões referentes ao tratamento dos dados pessoais.</p>

          <h3>5.2. O Encarregado</h3>
          <p>O Encarregado pela proteção de dados (Data Protection Officer – “DPO”) é a pessoa ou entidade nomeada por nós, para garantir a conformidade do tratamento de dados pessoais com a LGPD, assegurando a comunicação eficiente com os titulares dos dados e a cooperação com as autoridades de controle, fazendo ainda a ponte entre as diferentes áreas de atividade dentro da nossa empresa.</p>

          <h3>5.3. O Operador</h3>
          <p>O Operador é a pessoa natural ou jurídica, com acesso limitado que realiza o tratamento de dados pessoais em nome da  T-UP Consultoria e Soluções em TI, ou seja, o Controlador. </p>

          <h2>6. Período de Conservação de Dados Pessoais</h2>
          <p>Os dados são mantidos identificáveis apenas pelo tempo necessário para as finalidades do tratamento. Após o término dos serviços, os dados são arquivados, eliminados ou anonimizados.</p>

          <h2>7. Segurança dos Dados Pessoais</h2>
          <p>Protegemos a confidencialidade e segurança dos dados pessoais de nossos usuários, prevenindo acessos não autorizados e uso ou divulgação indevidos, por meio de medidas técnicas e organizacionais apropriadas. No entanto, nenhuma transmissão de dados pela internet ainda é 100% segura. </p>

          <h2>8. Direitos dos Usuários</h2>
          <p>De acordo com a legislação, os usuários podem solicitar apagar, acessar, corrigir e fazer portabilidade dos dados pessoais. O contato para tal deve ser feito através do e-mail: faleconosco@t-upsolucoes.com.br. Não discriminamos os usuários por exercerem quaisquer direitos de privacidade descritos acima.</p>

          <h2>9. Alterações na Política de Privacidade</h2>
          <p>Podemos atualizar esta Política de Privacidade periodicamente. O usuário pode consultar regularmente para se manter informado sobre eventuais alterações. A data da última atualização será indicada no início desta Política.</p>

          <h2>10. Contato</h2>
          <p>Caso o usuário tenha alguma dúvida ou preocupação relacionadas a esta Política de Privacidade e aos direitos garantidos pela legislação com o tratamento de dados pessoais, poderá entrar em contato conosco através do e-mail: faleconosco@t-upsolucoes.com.br ou diretamente com nosso Encarregado de Proteção de Dados (DPO), Carlos Eduardo pelo e-mail: eduardo@t-upsolucoes.com.br</p>
        </div>
      </div>
    </S.Wrapper>
  )
}

export default PoliticaPrivacidadeModal
