import styled from 'styled-components'

export const AboutContainer = styled.section`
  width: 100vw;
  height: 780px;

  .wrapperWithMaxWidth {
    margin: 0 auto;
    width: 100vw;
    max-width: 1920px;
  }

  .centralizedText {
    margin: 0 auto;
    width: 75%;
    padding-top: 100px;
  }

  @media (min-width: 1440px) and (max-width: 1820.9px) {
    height: 600px;
  }

  @media (min-width: 1000px) and (max-width: 1439.9px) {
    height: 458px;
  }

  @media (min-width: 600px) and (max-width: 999.9px) {
    height: max-content;
    padding-bottom: 130px;

    .centralizedText {
      width: 85%;
      padding-top: 100px;
    }
  }

  @media (max-width: 599.9px) {
    height: max-content;
    padding-bottom: 100px;

    .centralizedText {
      width: 85%;
      padding-top: 100px;
    }
  }
`
