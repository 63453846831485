import React, { useContext } from 'react'
import { InitialComponent } from '../../components/InitialComponent'
import { AboutComponent } from '../../components/AboutComponent/index'
import { ServiceComponent } from '../../components/ServiceComponent/index'
import { ProjectComponent } from '../../components/ProjectComponent/index'
import { DiferentialComponent } from '../../components/DiferencialComponent/index'
import { ClientComponent } from '../../components/ClientComponent/index'
import { Footer } from '../../components/Footer/index'
import { GlobalContext } from '../../context/GlobalContext'
import { FrameSinosserra } from '../FrameSinosserra/index'
import { OurProjects } from '../OurProjects/index'
import { FloatingIcon } from '../../components/FloatingIcon/index'
import { Contato } from '../../components/Contato/index'
import { FramePrati } from '../FramePrati/index'
import { FrameFinanceira } from '../FrameFinanceira/index'
import { CookieComponentRef } from '../../components/CookiePopup/index'
import { PoliticaPrivacidadeModal } from '../../components/PoliticaPrivacidade/index'

export const Home: React.FC = () => {
  const {
    openCookiesModal,
    openPoliticaPrivacidade,
    openComponentProject,
    openOurProjectComponent,
    openComponentProjectPrati,
    openComponentProjectFinanceira
  } = useContext(GlobalContext)

  return (
    <div>
      {!openComponentProject &&
      !openOurProjectComponent &&
      !openComponentProjectPrati &&
      !openComponentProjectFinanceira && (
        <>
          <InitialComponent />
          <AboutComponent />
          <ProjectComponent />
          <ServiceComponent />
          <DiferentialComponent />
          <ClientComponent />
          <Contato />
          <FloatingIcon />
        </>
      )}

      {openComponentProjectFinanceira && <FrameFinanceira />}

      {openComponentProjectPrati && <FramePrati />}

      {openComponentProject && <FrameSinosserra />}

      {openOurProjectComponent && <OurProjects />}
      <Footer />

      {openCookiesModal ? (<CookieComponentRef />) : null}
      {openPoliticaPrivacidade ? (<PoliticaPrivacidadeModal />) : null}
    </div>
  )
}
