import styled, { css } from 'styled-components'

export const Wrapper = styled.section`
  ${({ theme }) => css`
    width: 100vw;
    height: 1084px;
    background-color: ${theme.colors.blue};
    overflow: hidden;

    .wrapperWithMaxWidth {
      margin: 0 auto;
      width: 100%;
      max-width: 1920px;
    }

    .centralized {
      margin: 0 auto;
      width: 100%;
      display: flex;
    }

    .containerLeft,
    .containerRight {
      width: 50vw;
      height: 100%;
    }

    .containerRight {
      img {
        width: 100%;
        object-fit: cover;
      }
    }

    .wrapperTitle {
      padding-top: 100px;
      margin-left: 25%;
    }
    
    .wrapperContainerWithIcons {
      width: 50%;
      display: flex;
      flex-direction: column;
      margin-left: 25%;
      padding-top: 50px;
    }

    .wrapperBorders {
      width: max-content;
      height: max-content;
      display: flex;
      align-items: center;
      margin-bottom: 126px;
    }

    p {
      font-size: ${theme.font.sizes.small30};
      color: ${theme.colors.gray100};
      line-height: 35px;
      margin-left: 30px;
      font-weight: ${theme.font.weight[400]};
    }

    @media (min-width: 1650px) and (max-width: 1870.9px){
      height: 931px;

      .wrapperContainerWithIcons {
        padding-top: 42px;
      }

      .wrapperBorders {
        margin-bottom: 108px;
      }

      p {
        font-size: 2.5rem;
        line-height: 30px;
        margin-left: 25px;
      }
    }

    @media (min-width: 1440px) and (max-width: 1649.9px){
      height: 813px;

      .wrapperContainerWithIcons {
        padding-top: 37px;
      }
      .wrapperBorders {
        margin-bottom: 94px;
      }

      p {
        font-size: 2.2rem;
        line-height: 26px;
        margin-left: 22px;
      }
    }

    @media (min-width: 1250px) and (max-width: 1439.9px){
      height: 705px;

      .wrapperContainerWithIcons {
        padding-top: 32px;
      }
      .wrapperBorders {
        margin-bottom: 82px;
      }

      p {
        font-size: 1.9rem;
        line-height: 22px;
        margin-left: 19px;
      }
    }

    @media (min-width: 1100px) and (max-width: 1439.9px){
      height: 614px;

      .wrapperContainerWithIcons {
        padding-top: 26px;
      }

      .wrapperBorders {
        margin-bottom: 65px;
      }

      p {
        font-size: 1.5rem;
        line-height: 18px;
        margin-left: 15px;
      }
    }

    @media (min-width: 1000px) and (max-width: 1099.9px){
      height: 570px;

      .wrapperContainerWithIcons {
        padding-top: 26px;
      }

      .wrapperBorders {
        margin-bottom: 65px;
      }
  
      p {
        font-size: 1.5rem;
        line-height: 18px;
        margin-left: 15px;
      }
    }

    @media (min-width: 700px) and (max-width: 999.9px){
      height: 545px;

      .containerRight {
        img {
          width: 100%;
          height: 545px;
          object-fit: none;
        }
      }

      .wrapperTitle {
        padding-top: 100px;
        margin-left: 15%;
      }

      .wrapperContainerWithIcons {
        margin-left: 15%;
        padding-top: 26px;
      }

      .wrapperBorders {
        margin-bottom: 65px;
      }

      p {
        font-size: 1.5rem;
        line-height: 18px;
        margin-left: 15px;
      }
    }

    @media (min-width: 600px) and (max-width: 699.9px){
      height: 505px;

      .containerRight {
        img {
          width: 100%;
          height: 545px;
          object-fit: none;
        }
      }

      .wrapperTitle {
        padding-top: 100px;
        margin-left: 15%;
      }

      .wrapperContainerWithIcons {
        margin-left: 15%;
        padding-top: 0px;
      }
      .wrapperBorders {
        margin-bottom: 65px;
      }

      p {
        font-size: 1.2rem;
        line-height: 15px;
        margin-left: 15px;
      }
    }

    @media (max-width: 599.9px){
      height: max-content;

      .centralized {
        flex-direction: column;
      }

      .containerLeft {
        width: 100%;
      }

      .containerRight {
        width: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: none;
        }
      }

      .wrapperTitle {
        padding-top: 100px;
        margin-left: 7.5%;
      }

      .wrapperContainerWithIcons {
        margin-left: 7.5%;
        padding-top: 0px;
      }

      .wrapperBorders {
        margin-bottom: 65px;
      }

      p {
        font-size: 4vw;
        line-height: 4.7vw;
        margin-left: 15px;
      }
    }
  `}
`
