import styled, { css } from 'styled-components'
import ProjectComponentBackground from '../../assets/webP/projeto_background.webp'

export const Wrapper = styled.section`
  ${({ theme }) => css`
    width: 100vw;
    height: 1260px;
    padding-top: 100px;
    padding-bottom: 101px;
    background-image: url(${ProjectComponentBackground}); 
    background-size: cover;
    background-repeat: no-repeat;

    .centralizedComponent {
      margin: 0 auto;
      width: 100%;
      max-width: 1920px;
    }

    .centralized {
      margin: 0 auto;
      width: 75%;
    }

    .wrapperTextAndForm {
      width: 100%;
      display: flex;
    }

    .textAndContact {
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    .textWrapper {
      width: 50%;
    
      h5 {
        font-size: ${theme.font.sizes.small30};
        color: ${theme.colors.white};
        line-height: 45px;
        width: 428px;
        margin-bottom: 100px;
      }
    }

    .contactWrapper {
      width: 50%;
      justify-content: flex-start;
      h5 {
        font-size: ${theme.font.sizes.small30};
        color: ${theme.colors.white};
        line-height: 45px;
        width: 100%;
        margin-bottom: 20px;
        font-size: 25px;
      }
    }

      .email{
        display: flex;
        flex-direction: row;
        align-items: center;
        h5 {
          font-size: ${theme.font.sizes.small30};
          color: ${theme.colors.white};
          width: 600px;
          height: 100%;
          font-size: 23px;
          margin-left: 20px;
          margin-bottom: 0px;
          .break-line {
            display: block;
            margin-top: -0.5em; 
          }
        }
      }

      .whastapp{
        display: flex;
        flex-direction: row;
        align-items: center;
        h5 {
          font-size: ${theme.font.sizes.small30};
          color: ${theme.colors.white};
          width: 600px;
          height: 100%;
          font-size: 23px;
          margin-left: 20px;
          margin-bottom: 0px;
          .break-line {
            display: block;
            margin-top: -0.5em; 
          }
        }
      }

    @media (min-width: 1650px) and (max-width: 1870.9px) {
      height: max-content;
      padding-top: 100px;
      padding-bottom: 85px;

      .textWrapper {
        h5 {
          font-size: 25px;
          line-height: 38px;
          width: 367px;
        }
      }

      .email{
        width: 800px;
        h5 {
          font-size: 25px;
          width: 800px;
        }
      }

      .whastapp{
        width: 800px;
        h5 {
          font-size: 25px;
          width: 800px;
        }
      }

      .emailIcon{
        width: 70px;
        height: 70px;
      }

      .whastappIcon{
        width: 70px;
        height: 70px;
      }

    }

    @media (min-width: 1440px) and (max-width: 1649.9px) {
      height: max-content;
      padding-top: 100px;
      padding-bottom: 75px;

      .textWrapper {
        h5 {
          font-size: 22px;
          line-height: 33px;
          width: 321px;
        }
      }

      .textAndContact{
        width: 60%;
      }

      .contactWrapper{
        h5 {
          font-size: 22px;
        }
      }

      .email{
        width: 800px;
        h5 {
          font-size: 22px;
          width: 800px;
        }
      }

      .whastapp{
        width: 800px;
        h5 {
          font-size: 22px;
          width: 800px;
        }
      }

      .emailIcon{
        width: 65px;
        height: 65px;
      }

      .whastappIcon{
        width: 65px;
        height: 65px;
      }

    }

    @media (min-width: 1200px) and (max-width: 1439.9px) {
      height: max-content;
      padding-top: 100px;
      padding-bottom: 62px;

      .textWrapper {
        h5 {
          font-size: 18px;
          line-height: 28px;
          width: 267px;
        }
      }

      .textAndContact{
        width: 70%;
      }

      .contactWrapper{
        h5 {
          font-size: 18px;
        }
      }

      .email{
        width: 800px;
        h5 {
          font-size: 18px;
          width: 800px;
          .break-line {
            display: block;
            margin-top: -1em; 
          }
        }
      }

      .whastapp{
        width: 800px;
        h5 {
          font-size: 18px;
          width: 800px;
          .break-line {
            display: block;
            margin-top: -1em; 
          }
        }
      }

      .emailIcon{
        width: 55px;
        height: 55px;
      }

      .whastappIcon{
        width: 55px;
        height: 55px;
      }
    }

    @media (min-width: 1000px) and (max-width: 1199.9px) {
      height: max-content;
      padding-top: 100px;
      padding-bottom: 52px;

      .textWrapper {
        h5 {
          font-size: 15px;
          line-height: 23px;
          width: 222px;
        }
      }

      .textAndContact{
        width: 70%;
      }

      .contactWrapper{
        display: flex;
        flex-direction: column;
        width: 500px;
        h5 {
          font-size: 15px;
          line-height: 23px;
          width: 222px;
          margin-bottom: 20px;
        }
      }

      .email{
        display: flex;
        flex-direction: row;
        width: 600px;
        align-items: center;
        h5 {
          font-size: 15px;
          line-height: 23px;
          margin-bottom: 0px;
          width: 600px;
          .break-line {
            display: block;
            margin-top: 0em; 
          }
        }
      }

      .whastapp{
        display: flex;
        flex-direction: row;
        width: 600px;
        align-items: center;
        margin-top: 2em;
        h5 {
          font-size: 15px;
          line-height: 23px;
          margin-bottom: 0px;
          width: 600px;
          .break-line {
            display: block;
            margin-top: 0em; 
          }
        }
      }

      .emailIcon{
        width: 45px;
        height: 45px;
      }

      .whastappIcon{
        width: 45px;
        height: 45px;
      }
    }

    @media (min-width: 600px) and (max-width: 999.9px) {
      height: max-content;
      padding-top: 100px;
      padding-bottom: 52px;

      .centralized {
        width: 85%;
      }

      .textAndContact{
        width: 60%;
      }

      .textWrapper {
        h5 {
          font-size: 15px;
          line-height: 23px;
          width: 222px;
        }
      }

      .contactWrapper{
        display: flex;
        flex-direction: column;
        width: 222px;
        h5 {
          font-size: 15px;
          line-height: 23px;
          width: 222px;
          margin-bottom: 5px;
        }
      }

      .email{
        display: flex;
        flex-direction: row;
        width: 250px;
        h5 {
          font-size: 12px;
          margin-bottom: 0px;
          margin-left: 10px;
        }
      }

      .whastapp{
        display: flex;
        flex-direction: row;
        width: 250px;
        h5 {
          font-size: 10px;
          margin-bottom: 0px;
          margin-left: 10px;
        }
      }

      .emailIcon{
        width: 30px;
        height: 30px;
      }

      .whastappIcon{
        width: 30px;
        height: 30px;
      }

    }

    @media (max-width: 599.9px) {
      height: max-content;
      padding-top: 100px;
      padding-bottom: 70px;

      .centralized {
        width: 85%;
      }

      .wrapperTextAndForm {
        display: block;
      }

      .textWrapper {
        h5 {
          font-size: 4.8vw;
          line-height: 7vw;
          width: 70vw;
          margin-bottom: 5vw;
        }
      }

      .contactWrapper{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 80vw;
        h5 {
          font-size: 4.8vw;
          line-height: 7vw;
          width: 100%;
          margin-bottom: 5vw;
        }
      }

      .email{
        width: 100%;
        display: flex;
        flex-direction: row;
        h5 {
          font-size: 4vw;
          line-height: 7vw;
          width: 70vw;
          margin-bottom: 0vw;
          margin-left: 3vw;
        }
      }

      .whastapp{
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-top: 2vw;
        h5 {
          font-size: 4vw;
          line-height: 7vw;
          width: 70vw;
          margin-bottom: 0vw;
          margin-left: 3vw;
        }
      }

      .emailIcon{
        width: 10vw;
        height: 10vw;
      }

      .whastappIcon{
        width: 10vw;
        height: 10vw;
      }
    }
  `}
`
