import styled, { css } from 'styled-components'

type WrapperProps = {
  pWidth: string;
  h4Width: string;
  h4WidthTabletFormat: string;
  pWidthTabletFormat: string;
  h4WidthMobileFormat?: string;
  pWidthMobileFormat?: string;
  liWidthMobileFormat?: string;
  pLineHeightMobile?: string;
  marginTopMobileFirstP?: string;
}

export const Wrapper = styled.div<WrapperProps>`
  ${({
    theme,
    pWidth,
    h4Width,
    h4WidthTabletFormat,
    pWidthTabletFormat,
    h4WidthMobileFormat,
    pWidthMobileFormat,
    liWidthMobileFormat,
    pLineHeightMobile,
    marginTopMobileFirstP
  }) => css`
    @media (min-width: 1920px) {
    margin: 0 auto;
    width: 1920px;
    max-width: 1920px;
    height: max-content;

    
      h4 {
        font-size: ${theme.font.sizes.small30};
        margin-top: 73px;
        color: ${theme.colors.darkBlue};
        line-height: 45px;
        width: 880px;
      }
      
      .wrapper {
        margin: 0 auto;
        width: 75%;
      }
    
      .containerText {
         width: 100%;
      }    
      
      .marginTopP {
        margin-top: 60px;
      }
  
      .marginTopP2 {
        margin-top: 32px;
      }
  
      .marginLeftP {
        margin-left: 20px;
      }
      
      p { 
        font-size: ${theme.font.sizes.small25};  
        color: ${theme.colors.blue};
        line-height: 40px;
        width: 855px;
      }
  
      .wrapperLiText {
        display: flex;
        align-items: center;
        
        div {
          background-color: ${theme.colors.purple};
          width: 8px;
          height: 8px;
          border-radius: 50%;
        }
  
        li {
          list-style: none;
          font-size: ${theme.font.sizes.small25};
          color: ${theme.colors.blue};
          line-height: 40px;
          margin-left: 9px;
        }
      }
  
      .containerButtons {
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }
    }


    @media (max-width: 1920px) {
      margin: 0 auto;
      width: 100vw;
      max-width: 1920px;

      h4 {
        font-size: 1.5vw;
        margin-top: 3.7vw;
        color: ${theme.colors.darkBlue};
        line-height: 2.3vw;
        width: ${h4Width};
      }

      .wrapper {
        margin: 0 auto;
        width: 75%;
      }
    
      .containerText {
         width: 100%;
      }   
      
      .marginTopP {
        margin-top: 3.2vw;
      }
  
      .marginTopP2 {
        margin-top: 2vw;
      }
  
      .marginLeftP {
        margin-left: 1.1vw;
      }
      
      p { 
        font-size: 1.35vw;  
        color: ${theme.colors.blue};
        line-height: 1.8vw;
        width: ${pWidth};
      }
  
      .wrapperLiText {
        display: flex;
        align-items: center;
        
        div {
          background-color: ${theme.colors.purple};
          width: 0.5vw;
          height: 0.5vw;
          border-radius: 50%;
        }
  
        li {
          list-style: none;
          font-size: 1.35vw;
          color: ${theme.colors.blue};
          line-height: 1.8vw;
          margin-left: 0.6vw;
        }
      }
  
      .containerButtons {
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }
    }

    @media (max-width: 999px) {
      h4 {
        font-size: 2.3vw;
        margin-top: 3.7vw;
        line-height: 3vw;
        width: ${h4WidthTabletFormat};
      }

      .wrapper {
        margin: 0 auto;
        width: 85%;
      }
    
      .containerText {
         width: 100%;
      }   
      
      .marginTopP {
        margin-top: 4vw;
      }
  
      .marginTopP2 {
        margin-top: 3.4vw;
      }
  
      .marginLeftP {
        margin-left: 1.1vw;
      }
      
      p { 
        font-size: 2vw;  
        line-height: 3vw;
        width: ${pWidthTabletFormat};
      }
  
      .wrapperLiText {
        
        div {
          width: 0.8vw;
          height: 0.8vw;
        }
  
        li {
          font-size: 2vw;
          line-height: 3vw;
          margin-left: 0.9vw;
        }
      }
    }

    @media (max-width: 599px) {
      h4 {
        font-size: 4.9vw;
        margin-top: 14vw;
        line-height: 6.9vw;
        width: ${h4WidthMobileFormat};
      }

      .wrapper {
        margin: 0 auto;
        width: 85%;
      }
    
      .containerText {
         width: 100%;
      }   
      
      .marginTopP {
        margin-top: 14vw;
      }
  
      .marginTopP2 {
        margin-top: 9vw;
      }
  
      .marginLeftP {
        margin-left: 0vw;
      }
      
      p {
        margin-top: ${marginTopMobileFirstP} ;
        font-size: 4.5vw;  
        line-height: ${pLineHeightMobile ?? '6.4vw'};
        width: ${pWidthMobileFormat};
      }
  
      .wrapperLiText {
        align-items: flex-start;
        
        div {
          margin-top: 2.2vw;
          width: 2vw;
          height: 2vw;
        }
  
        li {
          width: ${liWidthMobileFormat};
          font-size: 4.5vw;
          line-height: 6.4vw;
          margin-left: 4vw;
          margin-bottom: 4vw;
        }
      }
    }
  `}
`

type WrapperButtonMoveNextOrBackProps = {
  marginTopButtons: string;
  marginTopButtonsInPx: string
  marginTopTabletFormat: string;
  marginTopMobileButtons: string;
}

export const WrapperButtonMoveNextOrBack = styled.div<WrapperButtonMoveNextOrBackProps>`
  ${({
    theme,
    marginTopButtons,
    marginTopButtonsInPx,
    marginTopTabletFormat,
    marginTopMobileButtons
  }) => css`
    @media (min-width: 1920px) {
      width: 1920px;
      max-width: 1920px;
      display: flex;
      justify-content: center;
      height: max-content;
      padding-bottom: 45px;

      .wrapperButton {
        margin: 0 auto;
        width: 75%;
        display: flex;
        justify-content: flex-end;
      }

      .marginRight {
        margin-right: 22px;
      }
        
      .button {
        width: 90px;
        height: 90px;
        border-radius: 50%;
        border: 3px solid ${theme.colors.gray};
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: ${marginTopButtonsInPx};
        cursor: pointer;

        .borderPurple {
          width: 62px;
          height: 62px;
          border: 4px solid ${theme.colors.purple};
          background-color: transparent;
          border-radius: 50%;

          display: flex;
          align-items: center;
          justify-content: center;

          .centerPurple {
            width: 35px;
            height: 35px;
            background-color: ${theme.colors.purple};
            border-radius: 50%;
              
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }

    @media (max-width: 1920px) {
      width: 100vw;
      height: max-content;

      .wrapperButton {
        margin: 0 auto;
        width: 75%;
        display: flex;
        justify-content: flex-end;
      }

      .marginRight {
        margin-right: 1.3vw;
      }
      
      .button {
        width: 5vw;
        height: 5vw;
        border-radius: 50%;
        border: 0.2vw solid ${theme.colors.gray};
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: ${marginTopButtons};
        cursor: pointer;

        .borderPurple {
          width: 3.75vw;
          height: 3.75vw;
          border: 0.25vw solid ${theme.colors.purple};
          background-color: transparent;
          border-radius: 50%;

          display: flex;
          align-items: center;
          justify-content: center;

          .centerPurple {
            width: 2.5vw;
            height: 2.5vw;
            background-color: ${theme.colors.purple};
            border-radius: 50%;
            
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }

    @media (max-width: 999.9px) {
      padding-bottom: 3vw;


      .wrapperButton {
        margin: 0 auto;
        width: 85%;
        display: flex;
        justify-content: flex-end;
      }
      
      .button {
        width: 8vw;
        height: 8vw;
        margin-top: ${marginTopTabletFormat};
        border: 0.45vw solid ${theme.colors.gray};

        .borderPurple {
          width: 6vw;
          height: 6vw;
          border: 0.55vw solid ${theme.colors.purple};

          .centerPurple {
            width: 4vw;
            height: 4vw;
          }
        }
      }
    }

    @media (max-width: 599.9px) {
      width: 100vw;
      height: max-content;
      padding-bottom: 3vw;
      
      .wrapperButton{
        width: 100%;
        display: flex;
        justify-content: center;
      }

      .marginRight {
        margin-right: 16vw;
      }
      
      .button {
        width: 13vw;
        height: 13vw;
        margin-top: ${marginTopMobileButtons};
        border: 0.6vw solid ${theme.colors.gray};

        .borderPurple {
          width: 10vw;
          height: 10vw;
          border: 0.8vw solid ${theme.colors.purple};

          .centerPurple {
            width: 7vw;
            height: 7vw;
          }
        }
      }
    }
  `}
`
