import styled, { css } from 'styled-components'

export const Wrapper = styled.section`
  ${({ theme }) => css`

  body {
    font-family: 'Nunito', sans-serif;
    color: #1E1E1E;
  }
  
  .modal {
    position: fixed;
    top: 50%;
    display: block;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%; 
    max-width: 900px; 
    border-radius: 10px;
    height: 90vh; 
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 9999;
    overflow-y: auto; 
  }
  
  .closeWrapper {
    position: relative;
    width: 100%
  }

  .close {
    position: absolute;
    right: 0;
    top: 0;
    padding: 4px;
    font-size: 28px;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    transition: 0.3s color;

    &:hover {
      color: red;
    }
  }
  
  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    max-width: 100%;
    margin: 0 auto;
  }

  h1 {
    font-weight: bold;
    color: #633189;
    font-size: 26px;
    text-align: center; 
    margin-bottom: 30px; 
  }
  
  h2 {
    font-weight: bold;
    color: #633189;
    font-size: 24px;
  }
  
  h3 {
    color: #633189;
    font-size: 24px;
  }
  
  p {
    color: #000; /* cor preta */
    font-size: 24px;
    margin-bottom: 40px; 
  }
  
  h4 {
    font-weight: bold;
    color: #633189;
    font-size: 24px;
    margin-bottom: 30px; 
  }

  .modal::-webkit-scrollbar {
    width: 12px;
  }
  
  .modal::-webkit-scrollbar-thumb {
    background-color: #633189; /* Cor da barra de rolagem */
    border-radius: 6px;
  }
  
  /* Remover as setas de rolagem no Firefox */
  .modal::-webkit-scrollbar-button {
    display: none;
  }
  
  /* Estilizar a barra de rolagem no Chrome, Safari e Edge */
  .modal {
    scrollbar-width: thin;
    scrollbar-color: #633189 transparent; /* Cor da barra de rolagem e cor de fundo */
  }
  
  /* Estilizar o "thumb" (a barra de rolagem propriamente dita) no Chrome, Safari e Edge */
  .modal::-webkit-scrollbar-thumb {
    background-color: #633189; /* Cor da barra de rolagem */
    border-radius: 6px;
  }
  
  /* Remover as setas de rolagem no Chrome, Safari e Edge */
  .modal::-webkit-scrollbar-button {
    display: none;
  }

  }`
}`
