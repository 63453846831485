import * as S from './styles'
import React, { useContext, useEffect } from 'react'

import theme from '../../theme/theme'

import { TitleToComponent } from '../../components/TitleToComponent/index'
import { GlobalContext } from '../../context/GlobalContext'
import { Header } from '../../components/Header'
import { IoIosArrowForward } from 'react-icons/io'

import backgroundProjectSinosserra from '../../assets/webP/carSinosserra.webp'
import LoginImageSinosserra from '../../assets/webP/loginImageSinosserra.webp'
import MinhasPropostasImagemSinosserra from '../../assets/webP/minhasPropostasImagePortalSinosserra.webp'

export const FrameSinosserra: React.FC = () => {
  const {
    frameComponentRef,
    setControllerScroll,
    moveComponentProject,
    width
  } = useContext(GlobalContext)

  useEffect(() => {
    setControllerScroll(true)
    window.scroll({
      top: 0,
      behavior: 'smooth'
    })
  }, [])

  return (
    <S.Wrapper ref={frameComponentRef}>
      <section
        className='containerCar'
      >
      <Header />
        <img
          className="backgroundsinosserra"
          src={backgroundProjectSinosserra}
          alt="Background_Project_Sinossera"
        />

        <div className='wrapperWithMaxWidth'>
          <div className='wrapper'>
            <TitleToComponent
              borderTopWidth='50%'
              colorTitle={theme.colors.purple}
              titleToComponent='Portal lojista Sinosserra'
              widthText='478px'
              fontSize='60px'
              lineHeight='60px'
              fontSize1650='51px'
              lineHeight1650='55px'
              borderTopWidth1650='42%'
              borderTopWidth1240='40%'
              fontSize1240='48px'
              lineHeight1240='48px'
              maisPrati850lineHeight='3.5vw'
              maisPrati850Width='45vw'
            />

            <p>
              O Grupo Sinosserra, um dos maiores
              grupos de concessionárias de veículos
              e serviços financeiros do Rio Grande
              do Sul, presente no mercado desde
              1947.
            </p>
          </div>
        </div>
      </section>

      <section className='aboutServiceAndDevelop'>
        <div className='wrapperWithMaxWidth'>
          <div className='wrapper'>
            <TitleToComponent
              borderTopWidth='30%'
              titleToComponent='Sobre o projeto'
              colorTitle={theme.colors.blue}
            />

            <p>
              O projeto buscava solucionar o acesso dos lojistas.<br></br>
              Desenvolvido para web, o portal atende mais de 1.000 lojas em todo o Brasil.
            </p>

            <p className='marginTopSecoundP'>
              O ambiente construído fortaleceu a presença da marca e trouxe eficiência e praticidade para os usuário.
            </p>
          </div>
        </div>
      </section>

      <section className='containerWithImageOfPortalSinosserra'>
        <div className='wrapperWithMaxWidth'>
          <div className='wrapper'>
            <img
              className='loginImage'
              src={LoginImageSinosserra}
              alt="Page_Login_sinnosserraPortal"
            />
            <img
              className='minhasPropostasImage'
              src={MinhasPropostasImagemSinosserra}
              alt="Page_Login_sinnosserraPortal"
            />

          <div className='wrapperText'>
            <p>Gestão, Design (UI/UX), Desenvolvimento WEB responsivo e Sustentação.</p>
          </div>
          </div>
        </div>
      </section>

      <section className='nextProject'>
        <div className='wrapperWithMaxWidth'>
          <div className='wrapper'>
            <div className='wrapperText'>
              <p>Próximo projeto</p>

              <h2>Crédito Pessoal Sinosserra</h2>
            </div>

            <div className='wrapperIcon' onClick={() => moveComponentProject(1)}>
              <div>
                  <IoIosArrowForward color={theme.colors.white} size={width > 600 ? '24px' : '5vw'} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </S.Wrapper>
  )
}
