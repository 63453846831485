const theme = {
  colors: {
    black: '#222222',
    blackRGBA: 'rgba(34, 34, 34, 0.8)',
    blue: '#008DAB',
    blueBackground: '#42ABC6',
    blueLight: '#46AABF',
    blueRGBA: 'rgba(0, 141, 171, 0.6)',
    darkBlue: '#272E5D',
    gray: '#AEB4A6',
    gray100: '#E5E5E5',
    grayC4: '#C4C4C4',
    purple: '#633189',
    white: '#ffffff',
    red: '#ff2220'
  },
  font: {
    family: {
      defaultNunito: 'Nunito'
    },
    sizes: {
      large130: '13rem',
      large115: '11.5rem',
      large80: '8.0rem',
      medium60: '6.0rem',
      medium50: '5.0rem',
      medium40: '4.0rem',
      small32: '3.2rem',
      small30: '3.0rem',
      small24: '2.4rem',
      small26: '2.6rem',
      small25: '2.5rem',
      small22: '2.2rem',
      small20: '2rem',
      small18: '1.8rem',
      small16: '1.6rem'
    },
    weight: {
      200: 200,
      300: 300,
      400: 400,
      500: 500,
      600: 600,
      700: 700,
      800: 800,
      900: 900
    }
  }
}

export default theme
