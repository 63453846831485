import styled, { css } from 'styled-components'

export const Wrapper = styled.header`
${({ theme }) => css`
  padding-top: 70px;

  .noEffect {
    position: static;
  }

  .effectHeader {
    width: 100vw;
    background-color: ${theme.colors.blackRGBA};
    position: fixed;
    top: 0%;
    left: 0%;
    height: 114px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 100;
  }

  .centralizedHeader {
    margin: 0 auto;
    width: 100%;
    max-width: 1920px;
  }

  .spacingForElements {
    margin: 0 auto;
    width: 75%;
    @media (max-width: 999px) {
      width: 85%;
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .logo {
    width: 140px;
    height: 48px;
    cursor: pointer;
    transition: transform 0.5s;

    &:hover {
      transform: scaleX(1.05) scaleY(1.05);
    }
  }

  .menu {
    display: flex;
    align-items: center;
    color: ${theme.colors.white};
    z-index: 100;    
  }

  .selectPage {
    margin-right: 33px;
    width: max-content;
    height: max-content;
    transition: border-color 0.8s;

    li {
      list-style: none;
      font-size: ${theme.font.sizes.small22};
      font-weight: 700;
      padding: 6px 41px 5px 34px;
      text-transform: uppercase;
      cursor: pointer;
    }

    &:hover {
      border: 3px solid ${theme.colors.blue};
      border-radius: 20px 0px 20px 20px;
    }
  }

  .selectPage:last-child {
    margin-right: 0px;
  }

 @media (min-width: 1000px) and (max-width: 1870.9px){
    .effectHeader {
      height: 100px;
    }

    .logo {
      width: 150px;
    }

    .selectPage {
      margin-right: 24px;

      li {
        font-size: 1.6rem;
        padding: 5px 38px 4px 32px;
      }
    }
  }

  @media (min-width: 700px) and (max-width: 999.9px) {
    padding-top: 70px;

    .effectHeader {
      height: 100px;
    }

    .centralizedHeader {
      width: 100%;
    }

    .spacingForElements {
      width: 85%;
    }

    .logo {
      width: 130px;
      height: 50px;
    }

    .selectPage {
      margin-left: 10px;
      margin-right: 15px;

      li {
        font-size: 1.5rem;
        padding: 2px 32px 3px 25px;
      }

      &:hover {
        border: 2px solid ${theme.colors.blue};
        border-radius: 10px 0px 10px 10px;
      }
    }
  }

  @media (max-width: 699.9px) {
    padding-top: 70px;

    .effectHeader {
      height: 80px;
    }

    .centralizedHeader {
      width: 100%;
    }

    .spacingForElements {
      width: 85%;
    }
  
    .logo {
      width: 80px;
      height: 30px;
    }

    .selectPage {
      margin-left: 5px;
      margin-right: 8px;

      li {
        font-size: 1.3rem;
        padding: 1.5px 13px 2.5px 8px;
      }

      &:hover {
        border: 1.5px solid ${theme.colors.blue};
        border-radius: 8px 0px 8px 8px;
      }
    }
  }

  @media (max-width: 399.9px) {
    padding-top: 12vw;

    .effectHeader {
      height: 17vw;
    }

    .centralizedHeader {
      width: 100%;
    }

    .spacingForElements {
      width: 85%;
    }

    .logo {
      width: 20vw;
      height: 8vw;
    }

    .selectPage {
      margin-left: 2vw;
      margin-right: 3.5vw;

      li {
        font-size: 3vw;
        padding: 0.6vw 2vw 1vw 1.4vw;
      }

      &:hover {
        border: 1.2vw solid ${theme.colors.blue};
        border-radius: 8px 0px 8px 8px;
      }
    }
  }
`}
`
