import styled, { css } from 'styled-components'

type WrapperProps = {
  paddingTop: string;
  paddingLeft?: string;
  iconArrow: boolean;
  marginTopWrapperTextAndIcon: string;
  fontSizeH3?: string;
  fontSizeP?: string;
}

export const Wrapper = styled.div<WrapperProps>`
  ${({
    theme,
    paddingTop,
    paddingLeft,
    iconArrow,
    marginTopWrapperTextAndIcon,
    fontSizeH3,
    fontSizeP
  }) => css`
    padding-top: ${paddingTop && paddingTop};

    .paddingLeft {
      padding-left: ${paddingLeft && `calc(${paddingLeft} - 3%)`};
    }
    
    width: 100%;
    height: max-content;
    max-width: 1920px;

    h3 {
      color: ${theme.colors.darkBlue};
      width: 840px;
      font-size: ${fontSizeH3 ?? theme.font.sizes.small32};
      line-height: 45px;
      font-weight: ${theme.font.weight[700]};
      padding-left: ${paddingLeft && `calc(${paddingLeft} - 3%)`};
    }

    .wrapperTextAndIcon {
      width: 82vw;
      max-width: 1650px;
      height: max-content;
      display: flex;
      align-items: center;
      justify-content: ${iconArrow ? 'space-between' : 'flex-start'};
      margin-top: ${marginTopWrapperTextAndIcon && marginTopWrapperTextAndIcon};
    }

    div {
      p {
        padding-left: ${paddingLeft && paddingLeft};
      }
    }

    p {
      width: 870px;
      font-size: ${fontSizeP ?? theme.font.sizes.small26};
      line-height: 37px;
      color: ${theme.colors.blue};
    }
    
    .wrapperIcon {
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }

    @media (min-width: 1871px) and (max-width: 2000.9px){  
      .wrapperTextAndIcon {
        width: 85.8vw;
      }
    }

    @media (min-width: 1440px) and (max-width: 1870.9px){  
      h3 {
        width: 640px;
        font-size:  ${fontSizeH3 ? '2.2rem' : '2.4rem'};
        line-height: 33px;
      }
      
      .wrapperTextAndIcon {
        width: 85.6vw;
        p {
          width: 630px;
          font-size: ${fontSizeP ? '1.8rem' : '1.9rem'};
          line-height: 30px;
        }
      }
    }

    @media (min-width: 1000px) and (max-width: 1439.9px){
      width: 100%;

      h3 {
        width: 510px;
        font-size: ${fontSizeH3 ? '1.9rem' : '1.8rem'};
        line-height: 24.4px;
      }

      .wrapperTextAndIcon {
        width: 85.6vw;
        margin-top: ${marginTopWrapperTextAndIcon && '8px'};
      }

      p {
        width: 560px;
        font-size: ${fontSizeP ? '1.6rem' : '1.5rem'};
        line-height: 21.4px;
      }
    }

    @media (max-width: 999.9px){
      width: 100%;

      h3 {
        width: 660px;
        font-size: ${fontSizeH3 ? '2rem' : '2.4rem'};
        line-height: 34px;
      }

      .wrapperTextAndIcon {
        width: 85vw;
        margin-top: ${marginTopWrapperTextAndIcon && '8px'};
      }

      p {
        width: 590px;
        font-size: ${fontSizeP ? '1.7rem' : '2rem'};
        line-height: 29px;
      }
    }

    @media (max-width: 714.9px){
      h3 {
        width: 560px;
        font-size: ${fontSizeH3 ? '1.7rem' : '2.2rem'};
        line-height: 30px;
      }

      p {
        width: 520px;
        font-size: ${fontSizeP ? '1.5rem' : '1.8rem'};
        line-height: 24px;
      }
    }

    @media (max-width: 649.9px){
      h3 {
        width: 500px;
        font-size: ${fontSizeH3 ? '1.5rem' : '2rem'};
        line-height: 30px;
      }

      p {
        width: 420px;
        font-size: ${fontSizeP ? '1.2rem' : '1.6rem'};
        line-height: 24px;
      }
    }

    @media (max-width: 599.9px){
      h3 {
        width: 75vw;
        font-size: 4.9vw;
        line-height: 6.9vw;
      }

      p {
        width: 70vw;
        font-size: 4.5vw;
        line-height: 6.4vw;
      }
    }
  `}
`
