import React from 'react'
import { ThemeProvider } from 'styled-components'
import { Home } from './pages/Home'
import GlobalStyles from './styles/global'
import theme from './theme/theme'

export const App: React.FC = () => (
  <ThemeProvider theme={theme}>
    <GlobalStyles />
    <Home />
  </ThemeProvider>
)

export default App
