import * as S from './styles'
import React, { useContext, useEffect } from 'react'

import theme from '../../theme/theme'

import { TitleToComponent } from '../../components/TitleToComponent/index'
import { GlobalContext } from '../../context/GlobalContext'
import { Header } from '../../components/Header'
import { IoIosArrowForward } from 'react-icons/io'

import pratiBackground from '../../assets/webP/pratiBackground.webp'
import pratiTopImageFrame from '../../assets/webP/pratiTopImage.webp'
import pratiBottomImageFrame from '../../assets/webP/pratiBottomImage.webp'

export const FramePrati: React.FC = () => {
  const {
    framePrati,
    setControllerScroll,
    moveComponentProject,
    width
  } = useContext(GlobalContext)

  useEffect(() => {
    setControllerScroll(true)

    if (framePrati.current) {
      window.scroll({
        top: 0,
        behavior: 'smooth'
      })
    }
  }, [])

  return (
    <S.Wrapper ref={framePrati}>
      <section
        className='containerCar'
      >
      <Header />
        <img
          className="backgroundsinosserra"
          src={pratiBackground}
          alt="Prati_Background"
        />

        <div className='wrapperWithMaxWidth'>
          <div className='wrapper'>
            <TitleToComponent
              borderTopColor={theme.colors.white}
              borderTopWidth='50%'
              colorTitle={theme.colors.white}
              titleToComponent='Plataforma +PraTi'
              widthText='400px'
              fontSize='60px'
              lineHeight='60px'
              fontSize1650='51px'
              lineHeight1650='60px'
              borderTopWidth1650='42%'
              borderTopWidth1240='40%'
              fontSize1240='48px'
              lineHeight1240='48px'
              maisPrati850lineHeight='8vw'
              maisPrati850Width='47vw'
              maisPrati650Width='30vw'
            />

            <p>É uma Iniciativa sem fins lucrativos que busca encontrar e capacitar novos talentos da área de TI.</p>
          </div>
        </div>
      </section>

      <section className='aboutServiceAndDevelop'>
        <div className='wrapperWithMaxWidth'>
          <div className='wrapper'>
            <TitleToComponent
              borderTopWidth='30%'
              titleToComponent='Sobre o projeto'
              colorTitle={theme.colors.blue}
            />

            <p>
              Hoje atuamos em toda a sustentação do site e suas funcionalidades,<br></br>
              também realizamos a implementação de novas funcionalidades quando necessário.</p>

            <p className='marginTopSecoundP'>
            Realizamos a implementação de uma ferramenta para realizar a melhor correspondência
            “match” entre candidatos e empresas mantenedoras.
            </p>
          </div>
        </div>
      </section>

      <section className='containerWithImageOfPortalSinosserra'>
        <div className='wrapperWithMaxWidth'>
          <div className='wrapper'>
            <img
              className='loginImage'
              src={pratiTopImageFrame}
              alt="Image_Prati"
            />
            <img
              className='minhasPropostasImage'
              src={pratiBottomImageFrame}
              alt="Image_Prati"
            />

          <div className='wrapperText'>
            <p>Sustentação, Gestão, Design e Desenvolvimento WEB</p>
          </div>
          </div>
        </div>
      </section>

      <section className='nextProject'>
        <div className='wrapperWithMaxWidth'>
          <div className='wrapper'>
            <div className='wrapperText'>
              <p>Próximo projeto</p>

              <h2>Sinosserra App lojas</h2>
            </div>

            <div className='wrapperIcon' onClick={() => moveComponentProject(3)}>
              <div>
                  <IoIosArrowForward color={theme.colors.white} size={width > 600 ? '24px' : '5vw'} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </S.Wrapper>
  )
}
