import React from 'react'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'
import theme from '../../../theme/theme'
import { TitleToComponent } from '../../TitleToComponent'
import * as S from './styles'

type NextPageComponentProps = {
  moveBackPage: React.MouseEventHandler<HTMLDivElement>;
  moveNextPage: React.MouseEventHandler<HTMLDivElement>
  title: string;
  borderTitleWidth: string;
  h4Text: string;
  h4Width: string;
  p1Text: string;
  p2Text?: string;
  pWidth: string;
  paragraph?: boolean;
  marginTopButtons: string;
  marginTopButtonsInPx: string;
  liText?: string[];
  marginTopTabletFormat: string;
  h4WidthTabletFormat: string;
  pWidthTabletFormat: string;
  widthTextResponsive?: string;
  h4WidthMobileFormat?: string;
  pWidthMobileFormat?: string;
  liWidthMobileFormat?: string;
  marginTopMobileButton: string;
  textHtml?: string;
  lineHeigthMobile?: string;
  pHtml?: string;
  pLineHeightMobile?: string;
  marginTopMobileFirstP?: string;
}

export const NextPageComponentOne: React.FC<NextPageComponentProps> = ({
  moveBackPage,
  moveNextPage,
  title,
  borderTitleWidth,
  h4Text,
  h4Width,
  p1Text,
  p2Text,
  pWidth,
  paragraph,
  marginTopButtons,
  marginTopButtonsInPx,
  marginTopTabletFormat,
  pWidthTabletFormat,
  h4WidthTabletFormat,
  widthTextResponsive,
  pWidthMobileFormat,
  h4WidthMobileFormat,
  liWidthMobileFormat,
  marginTopMobileButton,
  lineHeigthMobile,
  textHtml,
  pLineHeightMobile,
  pHtml,
  marginTopMobileFirstP,
  liText
}) => {
  const html = {
    pTextHtml: pHtml ?? ''
  }

  return (
    <S.Wrapper
      pWidth={pWidth}
      h4Width={h4Width}
      h4WidthTabletFormat={h4WidthTabletFormat}
      pWidthTabletFormat={pWidthTabletFormat}
      h4WidthMobileFormat={h4WidthMobileFormat}
      pWidthMobileFormat={pWidthMobileFormat}
      liWidthMobileFormat={liWidthMobileFormat}
      pLineHeightMobile={pLineHeightMobile}
      marginTopMobileFirstP={marginTopMobileFirstP}
    >
      <div className='wrapper'>
          <TitleToComponent
            borderTopWidth={borderTitleWidth}
            colorTitle={theme.colors.blue}
            titleToComponent={title}
            borderTopColor={theme.colors.purple}
            widthTextResponsive={widthTextResponsive}
            textHtml={textHtml}
            lineHeigthMobile={lineHeigthMobile}
          />

          <div className='containerText'>
            <h4>
              {h4Text}
            </h4>

            {!pHtml && <p className={liText !== undefined && liText?.length > 0
              ? 'marginTopP marginLeftP'
              : 'marginTopP'}>
                {p1Text}
              </p>}

            {!pHtml && <p className={paragraph ? 'marginTopP2' : ''}>{p2Text}</p>}

            {pHtml !== undefined && <p dangerouslySetInnerHTML={{ __html: html.pTextHtml }}></p>}

            {liText?.map((text, index) =>
              <div key={index + Math.random()} className='wrapperLiText'>
                <div></div>

                <li>{text}</li>
              </div>
            )}
          </div>
      </div>

      <S.WrapperButtonMoveNextOrBack
        marginTopButtons={marginTopButtons}
        marginTopButtonsInPx={marginTopButtonsInPx}
        marginTopTabletFormat={marginTopTabletFormat}
        marginTopMobileButtons={marginTopMobileButton}
      >
        <div className='wrapperButton'>
          <div
            className='button marginRight'
            onClick={moveBackPage}
          >
            <div className='borderPurple'>
              <div className='centerPurple'>
                <IoIosArrowBack color={'white'} size={'18px'} />
                </div>
            </div>
          </div>
          <div className='button' onClick={moveNextPage}>
            <div className='borderPurple'>
              <div className='centerPurple'>
                <IoIosArrowForward color={'white'} size={'18px'} />
                </div>
            </div>
          </div>
        </div>
      </S.WrapperButtonMoveNextOrBack>
    </S.Wrapper>
  )
}
