import * as S from '../../components/ProjectComponent/styles'
import React, { useContext, useEffect, useRef, useState } from 'react'

import theme from '../../theme/theme'

import { TitleToComponent } from '../../components/TitleToComponent/index'
import { IconWithBorders } from '../../components/IconWithBorders'
import { GlobalContext } from '../../context/GlobalContext'
import { Header } from '../../components/Header'

import pratiImage from '../../assets/webP/praTi.webp'
import sinosserraFinanceiraImage from '../../assets/webP/sinosserraFinanceira.webp'
import sinosserraImage from '../../assets/webP/sinosserra.webp'

export const OurProjects: React.FC = () => {
  const {
    ourProjectComponentRef,
    setControllerScroll,
    moveComponentProject,
    mobile,
    width,
    setWidth,
    setMobile
  } = useContext(GlobalContext)

  useEffect(() => {
    setControllerScroll(true)
    window.scroll({
      top: 0,
      behavior: 'smooth'
    })
  }, [])

  window.addEventListener('resize', () => {
    setWidth(window.screen.width)
    if (window.screen.width <= 599.9) {
      setMobile(true)
    } else {
      setMobile(false)
    }
  })

  const [moveScroll, setMoveScroll] = useState('1')
  const scrollBarWidth = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (scrollBarWidth.current) {
      scrollBarWidth.current?.addEventListener('scroll', () => {
        if (scrollBarWidth.current?.scrollLeft && scrollBarWidth.current?.scrollLeft < width) {
          setMoveScroll('1')
        }

        if (scrollBarWidth.current?.scrollLeft &&
          scrollBarWidth.current?.scrollLeft > width &&
          scrollBarWidth.current?.scrollLeft < width * 2) {
          setMoveScroll('2')
        }

        if (scrollBarWidth.current?.scrollLeft && scrollBarWidth.current?.scrollLeft > width * 2) {
          setMoveScroll('3')
        }
      })
    }
  }, [scrollBarWidth])

  return (
    <S.Wrapper
      ref={ourProjectComponentRef}
      moveScrollForBall={moveScroll}
      minHeight='80vh'
      display='flex'
      justifyContent='center'
      flexDirection='column'
    >
       <Header />
      <div className='wrapperWithMaxWidth'>
        <div className='centralized'>
          <div className="containerTitle">
            <TitleToComponent
              borderTopWidth='55%'
              colorTitle={theme.colors.white}
              borderTopColor={theme.colors.blue}
              titleToComponent='Projetos'
            />
          </div>
        </div>

        {mobile && (
          <div className='containerBalls'>

            <div className='ball1'><span className='center'></span></div>
            <div className='ball2'><span className='center'></span></div>
            <div className='ball3'><span className='center'></span></div>

          </div>
        )}

          <div className='centralizedGrid'>
            <div className="gridTable" ref={scrollBarWidth}>
              <div className='slider'>
                  <div
                    className="containerWithImage"
                  >
                    <div
                      className="wrapperImage" style={{ backgroundImage: `url(${sinosserraFinanceiraImage})` }}
                      onClick={() => moveComponentProject(1)}
                    >
                      <IconWithBorders
                        secoundBorderWidthAndHeight='60px'
                        centerWidthAndHeight='38px'
                        bsPlusLg={true}
                        firstBorderWidthAndHeight='80px'
                        firstBorderSize='2px'
                        secoundBorderSize='4px'
                        colorSecoundaryBorder={theme.colors.blue}
                        sizeIcon='10.22px'
                        cursorBsPlusLg='pointer'
                        marginTop='0px'
                      />
                    </div>

                    <h5>Sinosserra Financeira</h5>

                    <p>Design e desenvolvimento mobile.</p>
                  </div>

                  <div className="containerWithImage">
                    <div
                      className="wrapperImage" style={{ backgroundImage: `url(${pratiImage})` }}
                      onClick={() => moveComponentProject(2)}
                    >
                      <IconWithBorders
                        secoundBorderWidthAndHeight='60px'
                        centerWidthAndHeight='38px'
                        bsPlusLg={true}
                        firstBorderWidthAndHeight='80px'
                        firstBorderSize='2px'
                        secoundBorderSize='4px'
                        colorSecoundaryBorder={theme.colors.blue}
                        sizeIcon='10.22px'
                        cursorBsPlusLg='pointer'
                        marginTop='0px'
                      />
                    </div>

                    <h5>+PraTi</h5>

                    <p>Design e desenvolvimento mobile.</p>
                  </div>

                  <div className="containerWithImage">
                    <div
                      className="wrapperImage" style={{ backgroundImage: `url(${sinosserraImage})` }}
                      onClick={() => moveComponentProject(3)}
                    >
                      <IconWithBorders
                        secoundBorderWidthAndHeight='60px'
                        centerWidthAndHeight='38px'
                        bsPlusLg={true}
                        firstBorderWidthAndHeight='80px'
                        firstBorderSize='2px'
                        secoundBorderSize='4px'
                        colorSecoundaryBorder={theme.colors.blue}
                        sizeIcon='10.22px'
                        cursorBsPlusLg='pointer'
                        marginTop='0px'
                      />
                    </div>

                    <h5>Sinosserra</h5>

                    <p>Gestão, Design e desenvolvimento.</p>
                  </div>
                </div>
              </div>
          </div>
      </div>
    </S.Wrapper>
  )
}
