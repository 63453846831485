import * as S from './styles'
import React, { useContext } from 'react'

import theme from '../../theme/theme'

import { AboutServiceAndClient } from '../AboutServiceAndClient/index'
import { GlobalContext } from '../../context/GlobalContext'

export const AboutComponent: React.FC = () => {
  const { aboutComponentRef } = useContext(GlobalContext)

  return (
    <S.AboutContainer ref={aboutComponentRef}>
      <div className='wrapperWithMaxWidth'>
        <div className='centralizedText'>
          <AboutServiceAndClient
            paddingTop='0px'
            titleComponent='Sobre'
            marginTopWrapperTextAndIcon="38.5px"
            titleComponentBorderTopWidth='80%'
            colorTitle={theme.colors.blue}
            subTitle='A T-UP é uma empresa de tecnologia que tem como
            premissa entender as demandas do cliente e entregar
            resultados efetivos.'
            textP=' Nosso trabalho consiste em conhecer e estudar o core de sua empresa,
            identificar suas dores, entender suas necessidades, analisar dados,
            apontar soluções inovadoras e implementá-las com alto grau de
            compromisso com a evolução do seu negócio.'
            iconArrow={false}
          />
        </div>
      </div>
    </S.AboutContainer>
  )
}
