import React, { useContext } from 'react'
import * as S from './styles'

import WhatsAppIcon from '../../assets/svg/whatsapp.svg'
import * as CTX from '../../context/GlobalContext'

export const FloatingIcon: React.FC = () => {
  const { contatoComponentRef } = useContext(CTX.GlobalContext)

  function moveToContato () {
    if (contatoComponentRef.current) {
      window.scroll({
        top: contatoComponentRef.current.offsetTop,
        behavior: 'smooth'
      })
    }
  }

  function openWindowSocialNetworks () {
    const isMobile = window.innerWidth <= 768
    const url = isMobile ? 'whatsapp://send?phone=5551991450517' : 'https://web.whatsapp.com/send?phone=5551991450517'
    window.open(url, '_blank')
  }

  return (
    <S.Wrapper>
      <div className='relative'>
        <div className='FaleComAGente' onClick={() => { openWindowSocialNetworks(); moveToContato() }}>
            <p>Fale com  a gente!</p>
        </div>

        <div className='IconWhatsApp' onClick={() => { openWindowSocialNetworks(); moveToContato() }}>
          <div className='relativeBorder'>
            <div className='centerWhite'>
                <img src={WhatsAppIcon} alt="Icone_Tel" />
            </div>

            <div className='borderBlue'>
            </div>
          </div>
        </div>
      </div>
    </S.Wrapper>
  )
}
