import * as S from './styles'
import React, { useContext } from 'react'

import { AiFillInstagram } from 'react-icons/ai'
import { ImLinkedin } from 'react-icons/im'
import { GlobalContext } from '../../context/GlobalContext'

import LogoTup from '../../assets/webP/logo.webp'

export const Footer: React.FC = () => {
  const {
    setOpenComponentProject,
    setOurProjectComponent,
    setOpenPoliticaPrivacidade
  } = useContext(GlobalContext)

  function openWindowSocialNetworks (socialNetwork: string) {
    window.open(`${socialNetwork}`, '_blank')
  }

  const intagramTup = 'https://www.instagram.com/tupsolucoes/'
  const linkedinTup = 'https://www.linkedin.com/company/tupsolucoesemti/'

  function moveToTopAndHabilityLadingPage () {
    window.scroll({
      top: 0,
      behavior: 'smooth'
    })

    setOpenComponentProject(false)
    setOurProjectComponent(false)
  }

  return (
    <S.Wrapper>
      <div className='wrapperWithMaxWidth'>
        <div className='centralizedFooter'>
          <img
            className="t-upLogo"
            src={LogoTup}
            alt="Logo_T-up"
            title="T-up Soluções em TI"
            onClick={moveToTopAndHabilityLadingPage}
          />

          <div className="wrapperTextAndIcons">
            <div className="containerText">
                <h3>Precisa de alguma solução?</h3>

                <p className="marginBottom">Entra em contato com a gente.</p>

                <p className="marginBottom">contato@t-upsolucoes.com.br</p>

                <button className="botao-politica" onClick={() => setOpenPoliticaPrivacidade(true)}>Política de Privacidade</button>
            </div>

            <div className="iconsSocialNetworks">

              <AiFillInstagram
                color='white'
                size='58.6px'
                title='Instagram'
                cursor='pointer'
                className='svg1'
                onClick={() => openWindowSocialNetworks(intagramTup)}
              />

              <ImLinkedin
                color='white'
                size='43.6px'
                title='Linkedin'
                cursor='pointer'
                className='svg2'
                onClick={() => openWindowSocialNetworks(linkedinTup)}
              />

            </div>
          </div>
        </div>
      </div>
    </S.Wrapper>
  )
}
