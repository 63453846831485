import * as S from './styles'
import React, { useContext, useEffect } from 'react'

import theme from '../../theme/theme'

import { TitleToComponent } from '../../components/TitleToComponent/index'
import { GlobalContext } from '../../context/GlobalContext'
import { Header } from '../../components/Header'
import { IoIosArrowForward } from 'react-icons/io'

import financeiraFrameBackground from '../../assets/webP/financeira.webp'
import financeiraImageTop from '../../assets/webP/financeiraImageTop.webp'
import fincanceiraImageBottom from '../../assets/webP/financeiraImageBottom.webp'

export const FrameFinanceira: React.FC = () => {
  const {
    frameFinanceira,
    setControllerScroll,
    moveComponentProject,
    width
  } = useContext(GlobalContext)

  useEffect(() => {
    setControllerScroll(true)

    if (frameFinanceira.current) {
      window.scroll({
        top: 0,
        behavior: 'smooth'
      })
    }
  }, [])

  return (
    <S.Wrapper ref={frameFinanceira}>
      <section
        className='containerCar'
      >
      <Header />
        <img
          className="backgroundsinosserra"
          src={financeiraFrameBackground}
          alt="Financeira_Background"
        />

        <div className='wrapperWithMaxWidth'>
          <div className='wrapper'>
            <TitleToComponent
              borderTopWidth='32%'
              colorTitle={theme.colors.purple}
              titleToComponent='Crédito com Garantia em Veículo Sinosserra Financeira'
              widthText='620px'
              widthText1650='40vw'
              fontSize='60px'
              lineHeight='60px'
              fontSize1650='50px'
              lineHeight1650='65px'
              borderTopWidth1650='41%'
              borderTopWidth1240='40%'
              fontSize1240='48px'
              lineHeight1240='48px'
              lineHeigthMobile='9vw'
              widthTextResponsive='75vw'
              widthText750='63vw'
              lineHeight1440='5rem !important'
              titleWith1440='4.6rem !important'
            />

            <p>
              A Sinosserra Financeira faz parte do grupo Sinosserra, um dos maiores grupos
              de concessionárias de veículos do Rio Grande do Sul, atuando nesse mercado
              desde 1947 além de segmentos como consórcio, capitalização, seguros e
              financiamentos.
            </p>
          </div>
        </div>
      </section>

      <section className='aboutServiceAndDevelop'>
        <div className='wrapperWithMaxWidth'>
          <div className='wrapper'>
            <TitleToComponent
              borderTopWidth='30%'
              titleToComponent='Sobre o projeto'
              colorTitle={theme.colors.blue}
            />

            <p>
              O projeto tinha como objetivo criar um canal para permitir que pessoas que
              buscassem por uma alternativa de crédito com garantia em veículos, realizassem
              uma simulação e solicitação de crédito.
            </p>

            <p className='marginTopSecoundP'>
              Foi pensado em uma experiência fluida para simplificar cada etapa da jornada do usuário.
            </p>
          </div>
        </div>
      </section>

      <section className='containerWithImageOfPortalSinosserra'>
        <div className='wrapperWithMaxWidth'>
          <div className='wrapper'>
            <img
              className='loginImage'
              src={financeiraImageTop}
              alt="Imagem_Financeira"
            />
            <img
              className='minhasPropostasImage'
              src={fincanceiraImageBottom}
              alt="Imagem_Financeira"
            />

          <div className='wrapperText'>
            <p>Gestão, Design (UI/UX), Desenvolvimento WEB responsivo e Sustentação.</p>
          </div>
          </div>
        </div>
      </section>

      <section className='nextProject'>
        <div className='wrapperWithMaxWidth'>
          <div className='wrapper'>
            <div className='wrapperText'>
              <p>Próximo projeto</p>

              <h2>Plataforma de talentos</h2>
            </div>

            <div
              className='wrapperIcon'
            >
              <div onClick={() => moveComponentProject(2)}>
                  <IoIosArrowForward color={theme.colors.white} size={width > 600 ? '24px' : '5vw'}/>
              </div>
            </div>
          </div>
        </div>
      </section>
    </S.Wrapper>
  )
}
