import styled, { css } from 'styled-components'
import ProjectComponentBackground from '../../assets/webP/projeto_background.webp'

type WrapperProps = {
  moveScrollForBall: string;
  minHeight?: string;
  display?: string;
  justifyContent?: string;
  flexDirection?: string;
}

export const Wrapper = styled.section<WrapperProps>`
  ${({
    theme,
    moveScrollForBall,
    minHeight, display,
    justifyContent,
    flexDirection
  }) => css`
    width: 100vw;
    height: max-content;
    min-height: ${minHeight ?? ''};
    padding-top: 100px;
    padding-bottom: 4vw;
    display: ${display ?? ''};
    flex-direction: ${flexDirection ?? ''};
    justify-content: ${justifyContent ?? ''};
    background-image: url(${ProjectComponentBackground});
    background-size: cover;
    background-position-y: 40%;

    .wrapperWithMaxWidth {
      margin: 0 auto;
      width: 100%;
      max-width: 1920px;

    }
    
    .centralized {
      margin: 0 auto;
      width: 75%;
    }

    .containerBalls {
      width: 100vw;
      height: max-content;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10vw; 
    }

    .ball1,
    .ball2 {
      width: 3vw;
      height: 3vw;
      border: 0.4vw solid ${theme.colors.white};
      border-radius: 50%;
      margin-right: 7vw;
    }

    .center {
      width: 100%;
      height: 100%;
      background-color: ${theme.colors.white};
      border-radius: 50%;
    }

    .ball1 {
      .center {
        display: ${moveScrollForBall === '1' ? 'block' : 'none'};
      }
    }

    .ball2 {
      .center {
        display: ${moveScrollForBall === '2' ? 'block' : 'none'};
      }
    }

    .ball3 {
      .center {
        display: ${moveScrollForBall === '3' ? 'block' : 'none'};
      }
    }

    .ball3 {
      width: 3vw;
      height: 3vw;
      border: 0.4vw solid ${theme.colors.white};
      border-radius: 50%;
    }

    .centralizedGrid {
      margin: 0 auto;
      width: 75%;
    }

    .gridTable {
      width: 100%;
    }

    .slider {
      width: 100%;
      height: max-content;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 40px;
    }
    
    .containerWithImage {
      width: 100%;  
    }

    .wrapperImage {
      width: 100%;
      height: 630px;
      background-repeat: no-repeat;
      margin-bottom: 45px;
      background-size: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      filter: brightness(1.2);
      
      div {
        opacity: 0;
        transition: opacity 0.5s;
      }

      &:hover {
        div {
          opacity: 1;
        }
      }
    }

    h5 {
      color: ${theme.colors.white};
      font-weight: ${theme.font.weight[400]};
      font-size: ${theme.font.sizes.small26};
      line-height: 35px;
    }

    p {
      color: ${theme.colors.white};
      font-weight: ${theme.font.weight[200]};
      font-size: ${theme.font.sizes.small22};
      line-height: 35px;
    }  

    .buttonViewAllProject {
      width: 100%;
      height: max-content;
      display: flex;
      justify-content: flex-end;
      margin-top: 109px;
      padding-bottom: 70px;
      
      button {
        width: 379px;
        height: 40px;
        padding: 12px 59px 11px 58px;
        border-radius: 25px 0px 25px 25px;
        border: 3px solid ${theme.colors.blue};
        font-size: ${theme.font.sizes.small20};
        color: ${theme.colors.white};
        background-color: ${theme.colors.black};
        cursor: pointer;
        text-transform: uppercase;
        white-space: nowrap;
        display: flex;
        align-items: center;
        transition: 0.5s;
        
        &:hover {
          transform: scale3d(1.05, 1.05, 1);
        }
      }

    }

    @media (min-width: 1650px) and (max-width: 1820.9px){
      padding-top: 75px;
      .gridTable {
        gap: 34px;
      }

      .wrapperImage {
        height: 531px;
        margin-bottom: 38px;
      }

      h5 {
        font-size: 2.2rem;
        line-height: 30px;
      }

      p {
        font-size: 1.8rem;
        line-height: 30px;
      }  

      .buttonViewAllProject {
        margin-top: 50px;
        padding-bottom: 30px;
        button {
          width: 325px;
          height: 38px;
          padding: 10px 59px 9px 49px;
          font-size: 1.7rem;
          border: 2.7px solid ${theme.colors.blue};
        }
      }
    }

    @media (min-width: 1440px) and (max-width: 1649.9px) {
      .gridTable {
        gap: 30px;
      }

      .wrapperImage {
        height: 462px;
        margin-bottom: 33px;
      }

      h5 {
        font-size: 1.9rem;
        line-height: 26px;
      }

      p {
        font-size: 1.6rem;
        line-height: 26px;
      }  

      .buttonViewAllProject {
        margin-top: 50px;
        padding-bottom: 30px;
        button {
          width: 284px;
          height: 33px;
          padding: 9px 44px 8px 43px;
          font-size: 1.5rem;
          border: 2.4px solid ${theme.colors.blue};
        }
      }
    }

    @media (min-width: 1250px) and (max-width: 1439.9px){
      .gridTable {
        gap: 26px;
      }

      .wrapperImage {
        height: 410px;
        margin-bottom: 29px;
      }

      h5 {
        font-size: 1.6rem;
        line-height: 22px;
      }

      p {
        font-size: 1.4rem;
        line-height: 22px;
      }  

      .buttonViewAllProject {
        margin-top: 50px;
        padding-bottom: 30px;
        button {
          width: 246px;
          height: 29px;
          padding: 7px 38px 7px 37px;
          font-size: 1.3rem;
          border: 2px solid ${theme.colors.blue};
        }
      }
    }   
    
    @media (min-width: 1000px) and (max-width: 1249.9px){ 
      .gridTable {
        gap: 20px;
      }

      .wrapperImage {
        height: 370px;
        margin-bottom: 23px;
      }

      h5 {
        font-size: 1.3rem;
        line-height: 18px;
      }

      p {
        font-size: 1.1rem;
        line-height: 18px;
      }  

      .buttonViewAllProject {
        margin-top: 50px;
        padding-bottom: 30px;
        button {
          width: 197px;
          height: 23px;
          padding: 6px 30px 5px 30px;
          font-size: 1rem;
          border: 1.7px solid ${theme.colors.blue};
        }
      }
    }  

    @media (max-width: 999.99px){
      .centralized {
        width: 85%;
      }
      .centralizedGrid {
        width: 85%;
      }
      .gridTable {
        gap: 15px;
      }

      .wrapperImage {
        height: 340px;
        margin-bottom: 18px;
      }

      h5 {
        font-size: 1rem;
        line-height: 13px;
      }

      p {
        font-size: 1.3rem;
        line-height: 13px;
      }  

      .buttonViewAllProject {
        margin-top: 42px;
        padding-bottom: 27px;
        button {
          width: 230px;
          height: 24px;
          padding: 6px 34px 6px 33px;
          font-size: 1.2rem;
          border: 1.4px solid ${theme.colors.blue};
        }
      }
    }  

    @media (max-width: 819.99px){
      .gridTable {
        gap: 15px;
      }

      .wrapperImage {
        height: 290px;
        margin-bottom: 10px;
      }

      h5 {
        font-size: 1.4rem;
        line-height: 18px;
      }

      p {
        font-size: 1.1rem;
        line-height: 14px;
      }  
    }  

    @media (max-width: 749.9px){  
      .gridTable {
        gap: 12px;
      }

      .wrapperImage {
        height: 260px;
        margin-bottom: 19px;
      }

      h5 {
        font-size: 1.3rem;
        line-height: 16px;
      }

      p {
        font-size: 1.1rem;
        line-height: 13px;
      }  

      .buttonViewAllProject {
        margin-top: 34px;
        padding-bottom: 21px;
        button {
          width: 204px;
          height: 24px;
          padding: 6.5px 24px 6px 27px;
          font-size: 1rem;
          border: 1.2px solid ${theme.colors.blue};
        }
      }
    }  

    @media (max-width: 689.9px){   
      .wrapperImage {
        height: 220px;
        margin-bottom: 10px;
      }

      h5 {
        font-size: 1.2rem;
        line-height: 15px;
      }

      p {
        font-size: 0.9rem;
        line-height: 12px;
      }  

      .buttonViewAllProject {
        margin-top: 34px;
        padding-bottom: 21px;
        button {
          width: 194px;
          height: 25px;
          padding: 5px 24px 5px 24px;
          font-size: 1rem;
          border: 1.2px solid ${theme.colors.blue};
        }
      }
    }  

    @media (max-width: 599.9px){
      .centralizedGrid {
        width: 100%;
      }

      .gridTable {
        width: auto;
        overflow-x: auto;
        overflow-y: hidden;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      .slider {
        width: max-content;
        display: flex;
        align-items: center;
      }

      .containerWithImage {
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        width: 85vw;
        height: 120vw;
      }

      .wrapperImage {
        margin: 0 auto;
        background-size: cover;
        margin-right: auto;
        width: 75vw;
        height: 105vw;
        margin-bottom: 26px;
      }

      .adjustingMarginLeft {
        margin-left:7.5vw;
      }

      .adjustingMarginRight {
        margin-right:7.5vw;
      }

      h5 {
        font-size: 4vw;
        line-height: 4.7vw;
        margin-left: 8%;
      }

      p {
        font-size: 3.6vw;
        line-height: 4.3vw;
        margin-left: 8%;
      }  
      
      .buttonViewAllProject {
        justify-content: center;
        margin-top: 61px;
        padding-bottom: 50px;
            
        button {
          width: 50vw;
          height: 35px;
          border: 2px solid ${theme.colors.blue};
          display: flex;
          justify-content: center;
          font-size: 2.7vw;
          transition: none;
        }
      }  
    }

    @media (max-width: 374.9px){
      .containerWithImage {
        height: 130vw;
      }

      .wrapperImage {
        width: 70%;
        height: 79%;
        margin-bottom: 20px;
      }
      
      .buttonViewAllProject {
        margin-top: 41px;
      }  
    }
  `}
`
