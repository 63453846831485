import * as S from './styles'
import React, { useContext } from 'react'

import theme from '../../theme/theme'

import { AboutServiceAndClient } from '../AboutServiceAndClient'
import { GlobalContext } from '../../context/GlobalContext'

import logoSinSistemaIntegrado from '../../assets/webP/sin_sistemaIntegrado.webp'
import logoGrupoSinosserra from '../../assets/webP/grupo_sinosserra.webp'
import logoSinFinanceira from '../../assets/webP/sin_financeira_logo.webp'
import LogoBrowse from '../../assets/webP/ibrowse.webp'
import LogoLucree from '../../assets/webP/lucree.webp'
import LogoTramonto from '../../assets/webP/Logo-Tramonto.webp'
import LogoPrati from '../../assets/webP/pratiLogo.webp'
import LogoSinPromotora from '../../assets/webP/logo-sinosserra-promotora.webp'
import LogoGuaibacar from '../../assets/webP/Logos-Guaibacar.webp'
import LogoCotrijal from '../../assets/webP/logo_nova.webp'
import LogoSinosCar from '../../assets/webP/logo- Sinoscar.webp'

export const ClientComponent: React.FC = () => {
  const { width } = useContext(GlobalContext)

  const logos = [
    logoGrupoSinosserra,
    logoSinFinanceira,
    LogoSinPromotora,
    logoSinSistemaIntegrado,
    LogoGuaibacar,
    LogoSinosCar,
    LogoTramonto,
    LogoLucree,
    LogoPrati,
    LogoCotrijal,
    LogoBrowse
  ]

  return (
    <S.Wrapper>
      <div className='wrapperWithMaxWidth'>
        <div className='centralized'>
          <AboutServiceAndClient
            paddingTop='100px'
            fontSizeH3={'30px'}
            fontSizeP={'25px'}
            titleComponentBorderTopWidth={width < 600 ? '30%' : '70%'}
            titleComponent='Nossos clientes'
            colorTitle={theme.colors.blue}
            subTitle="A T-UP atende diferentes tipos de empresas."
            marginTopWrapperTextAndIcon='11.5px'
            textP='Algumas empresas onde a T-UP, orgulhosamente desenvolveu e
            implementou soluções inovadoras com alto grau de compromisso e evolução.'
            iconArrow={false}
          />

          <div className="gridTable">
          {logos.map((imagem, index) =>
            <div key={index}>
              <img className={
                index === 7
                  ? 'lucree'
                  : index === 8
                    ? 'prati'
                    : index === 10 ? 'ibrowse' : ''
              } src={imagem} alt="Imagem_Cliente" />
            </div>
          )}
          </div>
        </div>
      </div>
    </S.Wrapper>
  )
}
