import React, { useContext } from 'react'
import * as S from './styles'

import theme from '../../theme/theme'

import { AiOutlineWhatsApp, AiOutlineMail } from 'react-icons/ai'

import { TitleToComponent } from '../TitleToComponent'
import { Form } from '../Form/index'
import * as CTX from '../../context/GlobalContext'

function openWindowSocialNetworks () {
  const isMobile = window.innerWidth <= 768
  const url = isMobile ? 'whatsapp://send?phone=5551991450517' : 'https://web.whatsapp.com/send?phone=5551991450517'
  window.open(url, '_blank')
}

export const Contato: React.FC = () => {
  const {
    contatoComponentRef
  } = useContext(CTX.GlobalContext)

  return (
    <S.Wrapper ref={contatoComponentRef}>
      <div className='centralizedComponent'>
        <div className='centralized'>
          <TitleToComponent
            borderTopWidth='50%'
            colorTitle={theme.colors.white}
            titleToComponent='Contato'
            borderTopColor={theme.colors.blue}
          />

          <div className='wrapperTextAndForm'>
            <div className='textAndContact'>
              <div className='textWrapper'>
                <h5>Olá!<br></br> Para um melhor atendimento, dúvidas ou informações, fale com a gente.</h5>
              </div>
              <div className='contactWrapper'>
                <h5>Informações de contato</h5>
                <div className='email'>
                  <AiOutlineMail
                    color='white'
                    size='90px'
                    title='Email'
                    className='emailIcon'
                  />
                  <h5>E-mail
                    <span className="break-line">faleconosco@t-upsolucoes.com.br</span>
                  </h5>
                </div>
                <div className='whastapp'>
                  <AiOutlineWhatsApp
                    color='white'
                    size='90px'
                    title='WhatsApp'
                    cursor='pointer'
                    className='whastappIcon'
                    onClick={() => openWindowSocialNetworks()}
                  />
                  <h5>
                    <a href="#" onClick={openWindowSocialNetworks} style={{ color: 'white' }}>
                      Whatsapp
                    </a>
                    <span className="break-line">55 51-99145.0517</span>
                  </h5>
                </div>
              </div>
            </div>
          <Form />
          </div>
        </div>
      </div>
    </S.Wrapper>
  )
}
