import * as S from './styles'
import React, { useContext, useEffect } from 'react'

import { Header } from '../Header'
import { GlobalContext } from '../../context/GlobalContext'

export const InitialComponent:React.FC = () => {
  const {
    setControllerScroll
  } = useContext(GlobalContext)

  useEffect(() => {
    setControllerScroll(false)
  }, [])

  return (
    <S.Wrapper>
        <div className="wrapperWithMaxWidth">
          <Header />
          <div className='centralized'>
            <h1>Sua solução digital.</h1>
          </div>
        </div>
    </S.Wrapper>
  )
}
