import styled, { css } from 'styled-components'

export const Wrapper = styled.footer`
  ${({ theme }) => css`
    width: 100vw;
    height: max-content;
    background-color: ${theme.colors.blue};
    padding-top: 100px;
    padding-bottom: 35px;

    .wrapperWithMaxWidth {
      margin: 0 auto;
      width: 100%;
      max-width: 1920px;
    }
    .centralizedFooter {
      margin: 0 auto;
      width: 75%;
    }

    .t-upLogo {
      width: 148px;
      height: 49px;
      margin-bottom: 73px;
      cursor: pointer;
      transition: transform 0.5s;
      object-fit: contain;

      &:hover {
        transform: scaleX(1.05) scaleY(1.05);
      }
    }

    .wrapperTextAndIcons {
      width: 100%;
      height: max-content;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
    }

    .containerText {
      h3 {
        color: ${theme.colors.white};
        font-size: ${theme.font.sizes.small30};
        font-weight: ${theme.font.weight[700]};
        line-height: 50px;
      }

      p {
        font-weight: ${theme.font.weight[400]};
        font-size: ${theme.font.sizes.small25};
        color: ${theme.colors.white};
        width: max-content;
        line-height: 45px;
      }

      .marginBottom {
        margin-bottom: 18px;
      }

      .botao-politica {
        border: none;
        background: none;
        font-weight: ${theme.font.weight[400]};
        font-size: ${theme.font.sizes.small25};
        color: ${theme.colors.white};
        cursor: pointer;
        text-decoration: underline;
      }
    }

    .iconsSocialNetworks {
      display: flex;
      align-items: center;
    }

    .svg1 {
      margin-right: 42px;
    }

    @media (min-width: 1650px) and (max-width: 1870.9px){
      height: max-content;
      padding-top: 75px;

      .t-upLogo {
        width: 127px;
        margin-bottom: 62px;
      }

      .containerText {
        h3 {
          font-size: 2.5rem;
          line-height: 42px;
        }

        p {
          font-size: 2.1rem;
          line-height: 37px;
        }

        .marginBottom {
          margin-bottom: 15px;
        }

        .botao-politica{
          font-size: 2.1rem;
          line-height: 37px;
        }
      }
    }

    @media (min-width: 1440px) and (max-width: 1649.9px){
      height: max-content;
      padding-top: 75px;

      .t-upLogo {
        width: 111px;
        margin-bottom: 54px;
      }

      .containerText {
        h3 {
          font-size: 2.2rem;
          line-height: 30px;
        }

        p {
          font-size: 1.8rem;
          line-height: 25px;
        }

        .marginBottom {
          margin-bottom: 13px;
        }

        .botao-politica{
          font-size: 1.8rem;
          line-height: 25px;
        }
      }
    }

    @media (min-width: 1200px) and (max-width: 1439.9px){
      height: max-content;
      padding-top: 60px;

      .t-upLogo {
        width: 92px;
        margin-bottom: 40px;
      }

      .containerText {
        h3 {
          font-size: 1.8rem;
          line-height: 30px;
        }

        p {
          font-size: 1.5rem;
          line-height: 30px;
        }

        .marginBottom {
          margin-bottom: 11px;
        }

        .botao-politica{
          font-size: 1.5rem;
          line-height: 30px;
        }
      }

      .svg1 {
        width: 40px;
        height: 40px;
        margin-right: 30px;
      }

      .svg2 {
        width: 32px;
        height: 32px;
      }

    }

    @media (min-width: 1000px) and (max-width: 1199.9px){
      height: max-content;
      padding-top: 60px;

      .t-upLogo {
        width: 77px;
        margin-bottom: 30px;
      }

      .containerText {
        h3 {
          font-size: 1.5rem;
          line-height: 25px;
        }

        p {
          font-size: 1.3rem;
          line-height: 25px;
        }

        .marginBottom {
          margin-bottom: 9px;
        }

        .botao-politica{
          font-size: 1.3rem;
          line-height: 25px;
        }
      }

      .svg1 {
        width: 35px;
        height: 35px;
        margin-right: 25px;
      }

      .svg2 {
        width: 27px;
        height: 27px;
      }

    }

    @media (min-width: 600px) and (max-width: 999.9px){
      height: max-content;
      padding-top: 36px;

      .centralizedFooter {
        width: 85%;
      }

      .t-upLogo {
        width: 77px;
        margin-bottom: 30px;
      }

      .containerText {
        h3 {
          font-size: 1.5rem;
          line-height: 25px;
        }

        p {
          font-size: 1.3rem;
          line-height: 25px;
        }

        .marginBottom {
          margin-bottom: 9px;
        }

        .botao-politica{
          font-size: 1.3rem;
          line-height: 25px;
        }
      }

      .svg1 {
        width: 35px;
        height: 35px;
        margin-right: 25px;
      }

      .svg2 {
        width: 27px;
        height: 27px;
      }
    }

    @media (max-width: 599.9px){
      height: max-content;
      padding-top: 36px;

      .centralizedFooter {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .t-upLogo {
        margin: 0 auto;
        width: 77px;
        margin-bottom: 30px;
      }

      .wrapperTextAndIcons {
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }

      .containerText {

        text-align: center;
        
        h3 {
          font-size: 1.8rem;
          line-height: 32px;
          text-align: center;
        }

        p {
          font-size: 1.5rem;
          line-height: 28px;
          text-align: center;
          margin-left: 3vw;
        }

        .marginBottom {
          margin-bottom: 9px;
        }

        .botao-politica{
          font-size: 1.5rem;
          line-height: 28px;
          margin-left: 3vw;
        }
      }

      .svg1 {
        width: 48px;
        height: 48px;
        margin-right: 25px;
        margin-top: 7vw;
      }

      .svg2 {
        width: 37px;
        height: 37px;
        margin-top: 7vw;
      }
    }
  `}
`
