import * as S from './styles'
import React, { useContext } from 'react'

import theme from '../../theme/theme'

import { TitleToComponent } from '../TitleToComponent/index'
import { IconWithBorders } from '../IconWithBorders'
import { GlobalContext } from '../../context/GlobalContext'

import DiferencialBackgroundImage from '../../assets/webP/backgroundDiferencialComponent.webp'

export const DiferentialComponent: React.FC = () => {
  const { width } = useContext(GlobalContext)

  return (
    <S.Wrapper>
      <div className='wrapperWithMaxWidth'>
        <div className='centralized'>
          <div className='containerLeft'>
            <div className='wrapperTitle'>
              <TitleToComponent
                borderTopWidth='40%'
                colorTitle={theme.colors.white}
                titleToComponent="Diferencial"
              />
            </div>

            <div className="wrapperContainerWithIcons">
              <div className='wrapperBorders'>
                <IconWithBorders
                  colorIconOfMore={theme.colors.purple}
                  backgroundColorCenterContainer={theme.colors.white}
                  firstBorderSize='0px'
                  firstBorderWidthAndHeight={width > 599 && width < 1300 ? '5.5vw !important' : '60px !important'}
                  secoundBorderWidthAndHeight={width > 599 && width < 1300 ? '5.5vw !important' : '60px !important'}
                  secoundBorderSize='3px'
                  colorSecoundaryBorder={theme.colors.gray}
                  centerWidthAndHeight={width > 599 && width < 1300 ? '3.7vw !important' : '38px !important'}
                  marginTop='0px'
                  sizeIcon={width < 600 && width > 450 ? '3vw' : width <= 450 ? '12px' : '14px'}
                  bsPlusLg={true}
                />

                <p className="width1">Alto padrão<br></br>de entrega</p>
              </div>

              <div className='wrapperBorders'>
                <IconWithBorders
                  colorIconOfMore={theme.colors.purple}
                  backgroundColorCenterContainer={theme.colors.white}
                  firstBorderSize='0px'
                  firstBorderWidthAndHeight={width > 599 && width < 1300 ? '5.5vw !important' : '60px !important'}
                  secoundBorderWidthAndHeight={width > 599 && width < 1300 ? '5.5vw !important' : '60px !important'}
                  secoundBorderSize='3px'
                  colorSecoundaryBorder={theme.colors.gray}
                  centerWidthAndHeight={width > 599 && width < 1300 ? '3.7vw !important' : '38px !important'}
                  marginTop='0px'
                  sizeIcon={width < 600 && width > 450 ? '3vw' : width <= 450 ? '12px' : '14px'}
                  bsPlusLg={true}
                />

                <p className="width2">
                  Experiência em diferentes<br></br>
                  tipos de projetos e complexidades
                </p>
              </div>

              <div className='wrapperBorders'>
                <IconWithBorders
                  colorIconOfMore={theme.colors.purple}
                  backgroundColorCenterContainer={theme.colors.white}
                  firstBorderSize='0px'
                  firstBorderWidthAndHeight={width > 599 && width < 1300 ? '5.5vw !important' : '57px !important'}
                  secoundBorderWidthAndHeight={width > 599 && width < 1300 ? '5.5vw !important' : '57px !important'}
                  secoundBorderSize='3px'
                  colorSecoundaryBorder={theme.colors.gray}
                  centerWidthAndHeight={width > 599 && width < 1300 ? '3.7vw !important' : '35px !important'}
                  marginTop='0px'
                  sizeIcon={width < 600 && width > 450 ? '3vw' : width <= 450 ? '12px' : '14px'}
                  bsPlusLg={true}
                />

                <p className="width3">
                    Mais de 10 anos<br></br>
                    de desenvolvimento
                </p>
              </div>
            </div>
          </div>
          <div className='containerRight'>
            <img src={DiferencialBackgroundImage} alt="Ilustration Image" />
          </div>
        </div>
      </div>
    </S.Wrapper>
  )
}
