import styled from 'styled-components'

export const Wrapper = styled.section`
  width: 100vw;
  height: 1332px;

  .wrapperWithMaxWidth {
    margin: 0 auto;
    width: 100%;
    max-width: 1920px;
  }
  .centralized {
    margin: 0 auto;
    width: 75%;
  }

  .gridTable {
    width: 100%;
    height: max-content;
    margin-top: 117px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 2;
    gap: 90px;
    padding-bottom: 60px;

    div{
      width: 100%;
    }

    div:nth-child(2),
    div:nth-child(5),
    div:nth-child(8),
    div:nth-child(11) {
      display: flex;
      justify-content: center;
    }

    div:nth-child(3),
    div:nth-child(6),
    div:nth-child(9) {
      display: flex;
      justify-content: flex-end;
    }

    img {
      width: 280px;
      height: 90px;
      object-fit: contain;
      image-rendering: optimizeQuality;
    }

    .lucree {
      width: 400px;
      margin-left: -70px;
      height: 230px;
      margin-top: -75px;
    }

    .prati {
      height: 65px;
    }

    .ibrowse {
      height: 100px;
    }
  }

  @media (max-width: 1870.9px){
    height: max-content;

    .gridTable {
      margin-top: 100px;
      gap: 77px;
      padding-bottom: 51px;


      img {
        width: 60%;
      }
      .lucree {
        width: 343px;
      }

      .prati {
        height: 55px;
      }

      .ibrowse {
        height: 85px;
      }
    }
  }


  @media (max-width: 1649.9px){
    .gridTable {
      margin-top: 87px;
      gap: 67px;
      padding-bottom: 45px;


      img {
        width: 202px;
        height: 67px;
      }
      .lucree {
        width: 320px;
        margin-left: -62px;
        height: 160px;
        margin-top: -56px;
      }

      .prati {
        height: 48px;
      }

      .ibrowse {
        height: 75px;
      }
    }
  }

  @media (max-width: 1439.9px){
    .gridTable {
      margin-top: 76px;
      gap: 58px;
      padding-bottom: 39px;


      img {
        width: 175px;
        height: 58px;
      }

      .lucree {
        width: 300px;
        margin-left: -55px;
        height: 140px;
        margin-top: -53px;
      }

      .prati {
        height: 42px;
      }

      .ibrowse {
        height: 65px;
      }
    }
  }

  @media (max-width: 1199.9px){
    .gridTable {
      margin-top: 60px;
      gap: 46px;
      padding-bottom: 31px;

      div {
        width: 100%;
        justify-content: flex-start;

        img {
          width: 45%;
          height: 46px;
        }
        .lucree {
          width: 178px;
          margin-left: -30px;
          height: 109px;
          margin-top: -39px;
        }
  
        .prati {
          height: 33px;
        }
  
        .ibrowse {
          height: 52px;
        }
      }
    }
  }

  @media (max-width: 999.9px){
    .centralized {
      width: 85%;
    }


    .gridTable {
      width: 85vw;
      grid-template-columns: repeat(2, 1fr);
      margin-top: 60px;
      gap: 56px;
      padding-bottom: 31px;
      justify-content: center;
      
      div {
        display: flex;
        justify-content: center;
      }


      img {
        width: 42%;
        height: 46px;
      }
      .lucree {
        width: 258px;
        height: max-content;
      }

      .prati {
        height: 42px;
      }

      .ibrowse {
        height: 52px;
      }
      
      div:nth-child(2),
      div:nth-child(5),
      div:nth-child(8),
      div:nth-child(11) {
        justify-content: center !important;
      }
  
      div:nth-child(3),
      div:nth-child(6),
      div:nth-child(9) {
        justify-content: center !important;
      }
    }

  }

  @media (max-width: 799.99px){
    .gridTable {
      width: 85vw;
      grid-template-columns: repeat(2, 1fr);
      margin-top: 60px;
      gap: 46px;
      padding-bottom: 31px;

      img {
        width: 50%;
        height: 52px;
        margin-left: 6vw;
      }

      .lucree {
        width: 200px;
        margin-left: 2vw !important;
        height: 120px;
        margin-top: -37px;
      }

      .prati {
        height: 33px;
        margin-top: 8px;
      }

      .ibrowse {
        height: 52px;
      }

      div:nth-child(2),
      div:nth-child(5),
      div:nth-child(8),
      div:nth-child(11) {
        justify-content: flex-start;
      }
  
      div:nth-child(3),
      div:nth-child(6),
      div:nth-child(9) {
        justify-content: flex-start;
      }
    }
  }

 

  @media (max-width: 599.9px){
    .gridTable {
      margin: 0 auto;
      width: 85vw;
      padding-left: 0px;
      grid-template-columns: 1fr;
      margin-top: 135px;
      align-items: space-between;
      justify-content: center;
      gap: 95px;

      div{
        margin: 0 auto;
        width: 100%;
        display: flex;
        justify-content: center;
      }

      img {
        width: 60% !important;
        height: 100% !important;
        margin-left: 0vw;
        object-fit: cover;
      }

      .lucree {
        width: 80% !important;
        height: 150px !important;
      }

      .ibrowse {
        width: 30% !important;
        height: 100px !important;
        object-fit: contain !important;
      }

      .prati {
        width: 45% !important;
        height: max-content !important;
      }

      div:nth-child(2),
      div:nth-child(5),
      div:nth-child(8),
      div:nth-child(11) {
        justify-content: center;
      }
  
      div:nth-child(3),
      div:nth-child(6),
      div:nth-child(9) {
        justify-content: center;
      }
    }
  }
`
