import styled, { css } from 'styled-components'
import manInComputer from '../../assets/webP/man_in_computer.webp'

export const Wrapper = styled.main`
  ${({ theme }) => css`
    width: 100vw;
    height: 98vh;
    background-image: url(${manInComputer});
    background-size: cover;
    overflow: hidden;
    position: relative;
    background-repeat: no-repeat;

    .wrapperWithMaxWidth {
      margin: 0 auto;
      width: 100%;
      max-width: 1920px;
    }

    .centralized {
      margin: 0 auto;
      width: 70%;
    }

    h1{
      color: ${theme.colors.white};
      margin-top: 190px;
      width: 482px;
      font-size: ${theme.font.sizes.large115};
      line-height: 105px;
      font-weight: ${theme.font.weight[900]};
    }

    @media (min-width: 1440px) and (max-width: 1870.9px){
      h1{
        font-size: 8.6rem;
        width: 361px;
        line-height: 80px;
        margin-top: 100px;
      }
    }

    @media (min-width: 1000px) and (max-width: 1439.9px){
      h1{
        font-size: 5.9rem;
        line-height: 55.5px;
        width: 300px;
        margin-top: 25vh;
      }
    }

    @media (max-width: 999.9px){
      height: max-content;
      padding-bottom: 37vw;
      background-position: 50%;

      .centralized {
        margin: 0 auto;
        width: 85%;
      }
      
      h1{
        margin-top: 25vh;
        font-size: 7.9rem;
        width: 200px;
        line-height: 82px;
      }
    }

    @media (max-width: 699.9px){
      padding-bottom: 45vw;
      background-position: 43%;

      h1{
        font-size: 5.8rem;
        width: 200px;
        line-height: 62px;
      }
    }

    @media (max-width: 529.9px){
      padding-bottom: 38vw;
      background-position: 43%;
    }
  `}
`
