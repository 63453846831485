import styled, { css } from 'styled-components'

export const Wrapper = styled.section`
  ${({ theme }) => css`
    width: 100vw;
    height: max-content;

    .wrapperWithMaxWidth {
      margin: 0 auto;
      width: 100%;
      max-width: 1920px;
    }


    .containerCar {
      width: 100%;
      height: 98vh;
      position: relative;
      display: block;

      .backgroundsinosserra {
        width: 100%;
        height: 100%;
        object-fit: cover;
        filter: brightness(0.7);
        position: absolute;
        top: 0%;
        left: 0%;
        z-index: -1;
      }  

      .wrapperWithMaxWidth {
        margin: 0 auto;
        width: 100%;
        max-width: 1920px;
      }

      .wrapper {
        margin: 0 auto;
        width: 75%;
        max-width: 1920px;
        height: 80vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
  
        p {
          width: 570px;
          color: ${theme.colors.white};
          font-size: ${theme.font.sizes.small32};
          line-height: 44px;
        }
      }
    }


    .aboutServiceAndDevelop {
      width: 100vw;
      height: max-content;
      padding-top: 100px;
      padding-bottom: 110px;

      .wrapper {
        margin: 0 auto;
        width: 75%;
      }

      p {
        width: 880px;
        color: ${theme.colors.darkBlue};
        font-size: ${theme.font.sizes.small26};
        font-weight: ${theme.font.weight[700]};
        line-height: 37px;
      }

      .marginTopSecoundP {
        margin-top: 50px;
      }
    }

    .containerWithImageOfPortalSinosserra {
      width: 100vw;
      height: max-content;
      background-color: ${theme.colors.black};
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 100px 0;

      .wrapperWithMaxWidth {
        margin: 0 auto;
        width: 100%;
        max-width: 1920px;
        display: flex;
        flex-direction: column;
      }

      .wrapper {
        margin: 0 auto;
        width: 75%;
      }

      img {
        width: 100%;
      }

      .minhasPropostasImage {
        margin-top: 120px;
      }

      .wrapperText {
        width: 100%;
        margin-top: 20px;

        p {
          color: ${theme.colors.white};
          font-size: ${theme.font.sizes.small26};
          line-height: 37px;
        }
      }
    }

    
    .nextProject {
      width: 100vw;
      height: 341px;
      background-color: ${theme.colors.white};

      .wrapper {
        margin: 0 auto;
        width: 75%;
        height: 341px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
     
      .wrapperText {
        width: max-content;
      }
        
        
      p {
        color: ${theme.colors.blue};
        font-size: ${theme.font.sizes.small22};
        line-height: 33px;
      }

      .wrapperText h2 {
        color: ${theme.colors.purple};
        font-size: ${theme.font.sizes.small30};
        line-height: 50px;
      }

      .wrapperIcon {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        border: 3px solid ${theme.colors.blue};
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        div {
          width: 40px;
          height: 40px;
          background-color: ${theme.colors.purple};
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
        }
      }
    }

    @media (min-width: 1440px) and (max-width: 1649.9px) {
      .containerCar {
        .wrapper {
          p {
            width: 490px;
            font-size: 2.8rem;
            line-height: 40px;
          }
        }
      }


      .aboutServiceAndDevelop {
        padding-bottom: 110px;

        p {
          width: 730px;
          font-size: 2.2rem;
          line-height: 26px;
        }

        .marginTopSecoundP {
          margin-top: 50px;
        }
      }

      .containerWithImageOfPortalSinosserra {
        .wrapperWithMaxWidth {
          .wrapperText {
            margin-top: 15px;

            p {
              font-size: 2.2rem;
              line-height: 26px;
            }
          }   
        }
      }

      
      .nextProject {
        p {
          font-size: 1.8rem;
          line-height: 29px;
        }

        .wrapperText h2 {
          font-size: 2.6rem;
          line-height: 46px;
        }

        .wrapperIcon {
          width: 65px;
          height: 65px;
          border: 2.6px solid ${theme.colors.blue};

          div {
            width: 35px;
            height: 35px;
          }
        }
      }
    }

    @media (min-width: 1240px) and (max-width: 1439.9px) {
      .containerCar {
        .wrapper {
          p {
            width: 420px;
            font-size: 2.4rem;
            line-height: 36px;
          }
        }
      }


      .aboutServiceAndDevelop {
        padding-bottom: 110px;

        p {
          width: 700px;
          font-size: 1.9rem;
          line-height: 22px;
        }

        .marginTopSecoundP {
          margin-top: 45px;
        }
      }

      .containerWithImageOfPortalSinosserra {
        .wrapperWithMaxWidth {
          .wrapperText {
            margin-top: 12px;

            p {
              font-size: 1.9rem;
              line-height: 22px;
            }
          }   
        }
      }

      
      .nextProject {
        p {
          font-size: 1.8rem;
          line-height: 29px;
        }

        .wrapperText h2 {
          font-size: 2.6rem;
          line-height: 46px;
        }

        .wrapperIcon {
          width: 65px;
          height: 65px;
          border: 2.6px solid ${theme.colors.blue};

          div {
            width: 35px;
            height: 35px;
          }
        }
      }
    }

    @media (min-width: 1000px) and (max-width: 1239.9px) {
      .containerCar {
        img {
          object-position: 80% 100% !important;
        }
        .wrapper {
          p {
            width: 350px;
            font-size: 2rem;
            line-height: 32px;
          }
        }
      }


      .aboutServiceAndDevelop {
        padding-bottom: 110px;

        p {
          width: 520px;
          font-size: 1.5rem;
          line-height: 18px;
        }

        .marginTopSecoundP {
          margin-top: 40px;
        }
      }

      .containerWithImageOfPortalSinosserra {
        .wrapperWithMaxWidth {
          .wrapperText {
            margin-top: 10px;

            p {
              font-size: 1.5rem;
              line-height: 18px;
            }
          }   
        }
      }

      
      .nextProject {
        p {
          font-size: 1.8rem;
          line-height: 25px;
        }

        .wrapperText h2 {
          font-size: 2.3rem;
          line-height: 37px;
        }

        .wrapperIcon {
          width: 60px;
          height: 60px;
          border: 2.3px solid ${theme.colors.blue};

          div {
            width: 30px;
            height: 30px;
          }
        }
      }
    }

    @media (max-width: 999.9px) {
      .containerCar {
        height: 63vh;
        .backgroundsinosserra {
          width: 100%;
          height: 100%;
          object-position: -40vw !important;
          @media (max-width: 880px) {
            object-position: -120vw !important;
          }

          @media (max-width: 800px) {
            object-position: -107vw !important;
          }
        } 
        .wrapper {
          width: 85%;
          margin-top: -20vw;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          
          p {
            width: 70vw;
            font-size: 4.1vw;
            line-height: 5.3vw;
            margin-top: 10vw;
          }
        }
      }


      .aboutServiceAndDevelop {
        padding-bottom: 110px;
        .wrapper {
          width: 85%;
        }
        p {
          width: 70vw;
          font-size: 3.6vw;
          line-height: 4.7vw;
        }

        .marginTopSecoundP {
          margin-top: 40px;
        }
      }

      .containerWithImageOfPortalSinosserra {
        padding: 60px 0;
        .wrapperWithMaxWidth {
          .wrapper {
            width: 85%;
          }

          .wrapperText {
            margin-top: 7vw;

            p {
              width: 48vw;
              font-size: 3vw;
              line-height: 3.8vw;
            }
          }   
        }
      }

      
      .nextProject {
        height: 35vw;
        p {
          font-size: 3.6vw;
          line-height: 4.7vw;
        }

        .wrapper {
          width: 85%;
          height: 35vw;
        }

        .wrapperText h2 {
          font-size: 4.3vw;
          line-height: 5.1vw;
        }

        .wrapperIcon {
          width: 9.5vw;
          height: 9.5vw;
          border: 1px solid ${theme.colors.blue};

          div {
            width: 6vw;
            height: 6vw;
          }
        }
      }
    }
  `}
`
