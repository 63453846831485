import * as S from './styles'
import React, { useContext, useEffect, useState } from 'react'

// import { VscMenu } from 'react-icons/vsc'
import { GlobalContext } from '../../context/GlobalContext'

import LogoTup from '../../assets/webP/logo.webp'

export const Header: React.FC = () => {
  const {
    controllerScroll,
    setOpenComponentProject,
    setOurProjectComponent,
    setPositionToMoveScroll,
    setOpenComponentProjectPrati,
    setOpenComponentProjectFinanceira
  } = useContext(GlobalContext)

  const [effectScroll, setEffectScroll] = useState('')

  useEffect(() => {
    if (controllerScroll) {
      setEffectScroll('effectHeader')
    } else if (!controllerScroll) {
      setEffectScroll('noEffect')
    }
  }, [controllerScroll])

  function moveToTopAndHabilityLadingPage () {
    window.scroll({
      top: 0,
      behavior: 'smooth'
    })

    setOpenComponentProject(false)
    setOurProjectComponent(false)
    setOpenComponentProjectPrati(false)
    setOpenComponentProjectFinanceira(false)
  }

  function moveToComponentCorrespondent (numberRef: number) {
    setOpenComponentProject(false)
    setOurProjectComponent(false)
    setOpenComponentProjectPrati(false)
    setOpenComponentProjectFinanceira(false)
    setPositionToMoveScroll(numberRef)
  }

  return (
    <S.Wrapper>
      <div className={effectScroll}>
        <div className='centralizedHeader'>
          <div className="spacingForElements">
            <img
              onClick={moveToTopAndHabilityLadingPage}
              className="logo"
              src={LogoTup}
              alt="Logo"
              title="T-up Soluções em TI"
            />

            <nav className="menu">
              <div
                className="selectPage"
                onClick={() => moveToComponentCorrespondent(1)}
              >
                <li>Projetos</li>
              </div>
              <div
                className="selectPage"
                onClick={() => moveToComponentCorrespondent(2)}
              >
                <li>Serviços</li>
              </div>
              <div
                className="selectPage"
                onClick={() => moveToComponentCorrespondent(3)}
              >
                <li>Contato</li>
              </div>

              {/* <div className="menuHamburger">
                <VscMenu color={'white'} size={'40px'} cursor="pointer" />
              </div> */}
            </nav>
          </div>
        </div>
      </div>
    </S.Wrapper>
  )
}
