import styled, { css } from 'styled-components'

type WrapperProps = {
  borderTopWidth: string;
  borderTopWidth1650?: string;
  borderTopWidth1240?: string;
  borderTopColor?: string;
  colorTitle: string;
  widthText?: string;
  widthText1650?: string;
  widthTextResponsive?: string;
  marginBottomTitle?: string;
  fontSize?: string;
  fontSize1650?: string;
  fontSize1240?: string;
  lineHeight?: string;
  lineHeight1650?: string;
  lineHeight1240?: string;
  lineHeigthMobile?: string;
  widthText750?: string;
  maisPrati850Width?: string;
  maisPrati850lineHeight?: string;
  maisPrati650Width?: string;
  titleWith1440?: string;
  lineHeight1440?: string;
}

export const Wrapper = styled.div<WrapperProps>`
  ${({
    theme,
    colorTitle,
    widthText,
    borderTopWidth,
    borderTopWidth1650,
    borderTopWidth1240,
    borderTopColor,
    widthTextResponsive,
    fontSize,
    lineHeight,
    fontSize1650,
    fontSize1240,
    widthText1650,
    lineHeight1650,
    lineHeight1240,
    widthText750,
    lineHeigthMobile,
    maisPrati850Width,
    maisPrati850lineHeight,
    maisPrati650Width,
    titleWith1440,
    lineHeight1440
  }) => css`
    h2{
      color: ${colorTitle && colorTitle};
      width: ${widthText ?? 'max-content'};
      margin-bottom: 100px;
      font-size: ${fontSize ?? theme.font.sizes.medium40};
      font-weight: ${theme.font.weight[700]};
      line-height: ${lineHeight ?? '45px'};

      @media (min-width: 1651px) and (max-width: 1840px) {
        font-size: ${titleWith1440};
        line-height: ${lineHeight1440};
        margin-bottom: ${titleWith1440 ? '1vw !important' : ''};
        width: ${titleWith1440 ? '33vw !important' : ''}
      }

      @media (min-width: 1440px) and (max-width: 1650.9px) {
        font-size: ${titleWith1440};
        line-height: ${lineHeight1440};
        margin-bottom: ${titleWith1440 ? '1vw !important' : ''};
        width: ${titleWith1440 ? '33vw !important' : ''}
      }
      
      &::before {
        content: '';
        width: ${borderTopWidth};
        height: 7px;
        background-color: ${borderTopColor ?? theme.colors.purple};
        display: flex;
        margin-bottom: 28px;
      }
    }
      
    @media (min-width: 1440px) and (max-width: 1750.9px){
      h2 {
        width: ${widthText1650};
        margin-bottom: 76px;
        font-size: ${fontSize1650 ?? '3.2rem'};
        line-height: ${lineHeight1650 ?? '34.75px'};

        &::before {
          width: ${borderTopWidth1650 ?? ''};
          content: '';
          height: 5.25px;
          margin-bottom: 21px;
        }
      }

    }
    
    @media (min-width: 1240px) and (max-width: 1439.9px){
      h2 {
        margin-bottom: 52px;
        font-size: ${fontSize1240 ?? '2.7rem'};
        line-height: ${lineHeight1240 ?? '28px'};

        &::before {
          width: ${borderTopWidth1240};
          height: 3.6px;
          margin-bottom: 16.58px;
        }
      }
    }

    @media (min-width: 1000px) and (max-width: 1239.9px){
      h2 {
        margin-bottom: 52px;
        font-size: ${fontSize1240 ?? '2rem'};
        line-height: ${lineHeight1240 ?? '23.64px'};

        &::before {
          width: ${borderTopWidth1240};
          height: 3.6px;
          margin-bottom: 14.58px;
        }
      }
    }

    @media (min-width: 850px) and (max-width: 999.9px){
      h2 {
        width: calc(${widthText1650}  + 20vw);
        margin-bottom: 58px;
        font-size: ${fontSize1650 ?? '3.3rem'};
        line-height: ${lineHeight1650 ?? '28.64px'};

        &::before {
          height: 3.6px;
          margin-bottom: 14.58px;
        }
      }
    }

    @media (min-width: 750px) and (max-width: 849.9px){
      h2 {
        width: ${widthText750};
        margin-bottom: 58px;
        font-size: ${fontSize1650 ? `calc(${fontSize1650} - 4px)` : '2.8rem'};
        line-height: ${maisPrati850lineHeight ?? lineHeigthMobile ? `calc(${lineHeigthMobile} - 2.5vw)` : '28.64px'};

        &::before {
          width: calc(${borderTopWidth1240} - 4px);
          height: 3.6px;
          margin-bottom: 14.58px;
        }
      }
    }

    @media (min-width: 650px) and (max-width: 749.9px){
      h2 {
        width: ${maisPrati650Width ?? `calc(${widthText1650} + 23vw)`};
        margin-bottom: 58px;
        font-size: ${fontSize1650 ? `calc(${fontSize1650} - 2vw)` : '2.5rem'};
        line-height: ${maisPrati850lineHeight ?? lineHeigthMobile ? `calc(${lineHeigthMobile} - 2.5vw)` : '28.64px'};

        &::before {
          width: calc(${borderTopWidth1240} - 4px);
          height: 3.6px;
          margin-bottom: 14.58px;
        }
      }
    }

    @media (min-width: 600px) and (max-width: 649.9px){
      h2 {
        width: calc(${widthText1650} + 25vw);
        margin-bottom: 58px;
        font-size: ${fontSize1650 ? `calc(${fontSize1650} - 2vw)` : '2.7rem'};
        line-height: ${lineHeigthMobile ? `calc(${lineHeigthMobile} - 2vw)` : '28.64px'};

        &::before {
          width: calc(${borderTopWidth1240} - 4px);
          height: 3.6px;
          margin-bottom: 14.58px;
        }
      }
    }

    @media (max-width: 650px) {
      h2 {
        width: ${maisPrati850Width};
      }
    }

    

    @media (min-width: 550px) and (max-width: 599.9px){
      h2 {
        width: calc(${widthText1650} + 45vw);
        font-size: ${fontSize1650 ? `calc(${fontSize1650} - 2vw)` : '7vw'};
        line-height: ${lineHeigthMobile ?? '7.5vw'};
        width: ${widthTextResponsive};

        &::before {
          width: calc(${borderTopWidth1240} - 4px);
          height: 3.6px;
          margin-bottom: 6.5vw;
        }
      }
    }

    @media (min-width: 500px) and (max-width: 549.9px){
      h2 {
        width: calc(${widthText1650} + 45vw);
        font-size: ${fontSize1650 ? `calc(${fontSize1650} - 3.3vw)` : '7vw'};
        line-height: ${lineHeigthMobile ? `calc(${lineHeigthMobile} - 1.5vw)` : '7.5vw'};
        width: calc(${widthTextResponsive} - 6vw);

        &::before {
          width: calc(${borderTopWidth1240} - 4px);
          height: 3.6px;
          margin-bottom: 6.5vw;
        }
      }
    }

    @media (min-width: 460px) and (max-width: 499.9px){
      h2 {
        width: calc(${widthText1650} + 22vw) !important;
        font-size: ${fontSize1650 ? `calc(${fontSize1650} - 4.9vw)` : '7vw'};
        line-height: ${lineHeigthMobile ? `calc(${lineHeigthMobile} - 2vw) !important` : '7.5vw'};
        width: ${widthTextResponsive};

        &::before {
          width: calc(${borderTopWidth1240} - 4px);
          height: 3.6px;
          margin-bottom: 6.5vw;
        }
      }
    }

    @media (min-width: 400px) and (max-width: 459.9px){
      h2 {
        width: calc(${widthText1650} + 37vw);
        font-size: ${fontSize1650 ? `calc(${fontSize1650} - 5.2vw)` : '7vw'};
        line-height: ${lineHeigthMobile ? `calc(${lineHeigthMobile} - 2vw) !important` : '7.5vw'};
        width: ${widthTextResponsive};

        &::before {
          width: calc(${borderTopWidth1240} - 4px);
          height: 3.6px;
          margin-bottom: 6.5vw;
        }
      }
    }

    @media (max-width: 399.9px){
      h2 {
        width: calc(${widthText1650} + 55vw);
        font-size: ${fontSize1650 ? `calc(${fontSize1650} - 6.8vw)` : '7vw'};
        line-height: ${lineHeigthMobile ? `calc(${lineHeigthMobile} - 0.5vw)` : '7.5vw'};
        width: ${widthTextResponsive};

        &::before {
          width: calc(${borderTopWidth1240} - 4px);
          height: 3.6px;
          margin-bottom: 6.5vw;
        }
      }
    }

    @media (max-width: 320.9px){
      h2 {
        width: calc(${widthText1650} + 55vw);
        font-size: ${fontSize1650 ? `calc(${fontSize1650} - 7.5vw)` : '7vw'};
        line-height: ${lineHeigthMobile ? `calc(${lineHeigthMobile} - 1vw)` : '7.5vw'};
        width: ${widthTextResponsive};

        &::before {
          width: calc(${borderTopWidth1240} - 4px);
          height: 3.6px;
          margin-bottom: 6.5vw;
        }
      }
    }
  `}
`
