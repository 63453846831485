export const maskTel = (value: string): string => {
  const newValue = value.replace(/\D/g, '')

  const firstPattern = newValue.replace(/(\d{2})(\d{4})(\d{4})/, '($1)$2-$3')
  const secondPattern2 = newValue.replace(/(\d{2})(\d{5})(\d{4})/, '($1)$2-$3')

  if (newValue.length === 10) {
    return firstPattern
  }

  if (newValue.length === 11) {
    return secondPattern2
  }

  if (newValue.length > 11 && newValue.includes('(') === false) {
    const length = newValue.length

    if (length >= 13) {
      const valueChange = newValue.substring(2, length)
      const value = valueChange.replace(/(\d{2})(\d{5})(\d{4})/, '($1)$2-$3')
      return value
    }

    if (length === 12) {
      const valueChange = newValue.substring(2, length)
      const value = valueChange.replace(/(\d{2})(\d{4})(\d{4})/, '($1)$2-$3')
      return value
    }
  }

  return newValue
}
