import * as S from './styles'
import React from 'react'

import { BsPlusLg } from 'react-icons/bs'
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io'

type IconWithBordersProps = {
  firstBorderWidthAndHeight: string;
  firstBorderSize: string;
  secoundBorderWidthAndHeight: string;
  secoundBorderSize: string;
  centerWidthAndHeight: string;
  bsPlusLg?: boolean;
  ioioArrowForward?: boolean;
  sizeIcon: string;
  marginTop: string;
  colorSecoundaryBorder: string;
  cursorBsPlusLg?: 'pointer';
  cursorIoIoArrowForward?: 'pointer';
  ioioArrowBack?: boolean;
  cursorIoIoArrowBack?: 'pointer';
  onClick?: () => void;
  colorIconOfMore?: string;
  backgroundColorCenterContainer?: string;
}

export const IconWithBorders: React.FC<IconWithBordersProps> = ({
  bsPlusLg,
  firstBorderWidthAndHeight,
  firstBorderSize,
  secoundBorderWidthAndHeight,
  secoundBorderSize,
  centerWidthAndHeight,
  marginTop,
  ioioArrowForward,
  sizeIcon,
  colorSecoundaryBorder,
  cursorBsPlusLg,
  cursorIoIoArrowForward,
  ioioArrowBack,
  cursorIoIoArrowBack,
  onClick,
  colorIconOfMore,
  backgroundColorCenterContainer
}) => {
  return (
    <S.Wrapper
      firstBorderWidthAndHeight={firstBorderWidthAndHeight}
      firstBorderSize={firstBorderSize}
      secoundBorderWidthAndHeight={secoundBorderWidthAndHeight}
      secoundBorderSize={secoundBorderSize}
      centerWidthAndHeight={centerWidthAndHeight}
      marginTop={marginTop}
      colorSecoundaryBorder={colorSecoundaryBorder}
      backgroundColorCenterContainer={backgroundColorCenterContainer}
      cursor={
        cursorBsPlusLg ||
        cursorIoIoArrowBack ||
        cursorIoIoArrowForward
      }
    >
      <div className="borderGray" onClick={onClick}>
        <div className="borderBlue" onClick={onClick}>
          <div className="iconOfMore" onClick={onClick}>
            { bsPlusLg && (
              <BsPlusLg color={colorIconOfMore ?? 'white'} size={sizeIcon} />
            )}

            { ioioArrowForward && (
              <IoIosArrowForward color={colorIconOfMore ?? 'white'} size={sizeIcon} />
            )}

            { ioioArrowBack && (
              <IoIosArrowBack color={colorIconOfMore ?? 'white'} size={sizeIcon} />
            )}
          </div>
        </div>
      </div>
    </S.Wrapper>
  )
}
