import * as S from './styles'
import React from 'react'

type TitleComponentProps = {
  titleToComponent: string;
  borderTopWidth: string;
  colorTitle: string;
  widthText?: string;
  borderTopColor?: string;
  widthTextResponsive?: string;
  fontSize?: string;
  lineHeight?: string;
  textHtml?: string;
  lineHeigthMobile?: string;
  fontSize1650?: string;
  lineHeight1650?: string;
  widthText1650?: string;
  borderTopWidth1650?: string;
  borderTopWidth1240?: string;
  fontSize1240?: string;
  lineHeight1240?: string;
  widthText750?: string;
  maisPrati850Width?: string;
  maisPrati850lineHeight?: string;
  maisPrati650Width?: string;
  titleWith1440?: string;
  lineHeight1440?: string;
}

export const TitleToComponent: React.FC<TitleComponentProps> = ({
  titleToComponent,
  borderTopWidth,
  borderTopWidth1650,
  colorTitle,
  widthText,
  widthText1650,
  borderTopColor,
  widthTextResponsive,
  fontSize,
  fontSize1650,
  lineHeight,
  lineHeight1650,
  lineHeigthMobile,
  textHtml,
  borderTopWidth1240,
  fontSize1240,
  lineHeight1240,
  widthText750,
  maisPrati850Width,
  maisPrati850lineHeight,
  maisPrati650Width,
  titleWith1440,
  lineHeight1440
}) => {
  const html = {
    text: textHtml ?? ''
  }

  return (
    <S.Wrapper
      borderTopWidth={borderTopWidth}
      colorTitle={colorTitle}
      widthText={widthText}
      borderTopColor={borderTopColor}
      widthTextResponsive={widthTextResponsive}
      fontSize={fontSize}
      lineHeight={lineHeight}
      lineHeigthMobile={lineHeigthMobile}
      fontSize1650={fontSize1650}
      widthText1650={widthText1650}
      lineHeight1650={lineHeight1650}
      borderTopWidth1650={borderTopWidth1650}
      borderTopWidth1240={borderTopWidth1240}
      fontSize1240={fontSize1240}
      lineHeight1240={lineHeight1240}
      widthText750={widthText750}
      maisPrati850Width={maisPrati850Width}
      maisPrati850lineHeight={maisPrati850lineHeight}
      maisPrati650Width={maisPrati650Width}
      titleWith1440={titleWith1440}
      lineHeight1440={lineHeight1440}
    >
      {!textHtml && <h2>{titleToComponent}</h2>}
      {textHtml !== undefined && <h2 dangerouslySetInnerHTML={{ __html: html.text }}></h2>}
    </S.Wrapper>
  )
}
