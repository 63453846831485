type objectValidation = {
  error: string;
  success: boolean
}

export const validBeforeSendForBackend = (
  name: string,
  email: string,
  phone: string
): objectValidation => {
  const objectValid = {
    error: '',
    success: true
  }

  if (name.trim() === '' || name.trim().length < 3 || name.trim().length > 55) {
    objectValid.error = 'name'
    objectValid.success = false
    return objectValid
  } else {
    objectValid.error = ''
    objectValid.success = true
  }

  const validEmail1 = email.match(/(@)[a-z|0-9]/g)
  const lengthValid1 = validEmail1 && validEmail1.length === 1

  const validEmail2 = email.match(/(.com)$/g)

  const validEmail3 = email.match(/(.com.br)$/g)

  if (email === '' || email.length < 11 || email.length > 80 || lengthValid1 !== true) {
    objectValid.error = 'email'
    objectValid.success = false
    return objectValid
  } else if (validEmail2 === null && validEmail3 === null) {
    objectValid.error = 'email'
    objectValid.success = false
    return objectValid
  } else {
    objectValid.error = ''
    objectValid.success = true
  }

  const newPhone = phone.replace(/[()-]/g, '')

  if (newPhone.length < 10) {
    objectValid.error = 'phone'
    objectValid.success = false
    return objectValid
  } else {
    objectValid.error = ''
    objectValid.success = true
  }

  return objectValid
}
