import React, { useContext, useEffect, useState } from 'react'
import * as S from './styles'
import { validBeforeSendForBackend } from './ValidationForm/validForm'
import { maskTel } from './maskForm/maskTel'
import { GoVerified } from 'react-icons/go'
import { MdOutlineError } from 'react-icons/md'
import theme from '../../theme/theme'
import { GlobalContext } from '../../context/GlobalContext'
import emailjs from '@emailjs/browser'

export const Form: React.FC = () => {
  const { width } = useContext(GlobalContext)

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [optionalText, setOptionalText] = useState('')
  const [errors, setErros] = useState('')
  const [submited, setSubmited] = useState(false)
  const [popup, setPopup] = useState(false)
  const [popupError, setPopupError] = useState(false)
  const [loading, setLoading] = useState(false)

  const [timer, setTimer] = useState<any>()

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    setLoading(true)
    event.preventDefault()
    setSubmited(true)
    const valid = validBeforeSendForBackend(name, email, phone)
    if (!valid.success) {
      setLoading(false)
      setErros(valid.error)
    }

    if (valid.success === true) {
      const form = event.target as HTMLFormElement

      const getDate = new Date()
      const day = String(getDate.getUTCDate()).padStart(2, '0')
      const month = String(getDate.getMonth() + 1).padStart(2, '0')
      const year = String(getDate.getFullYear())
      const hours = getDate.getHours()
      const hoursConvert = String(getDate.getHours()).padStart(2, '0')
      const minutes = String(getDate.getMinutes()).padStart(2, '0')

      const inputGoodMessage = form.children[0] as HTMLInputElement
      inputGoodMessage.value = hours >= 0 && hours <= 6 ? 'boa noite' : hours > 6 && hours <= 12
        ? 'bom dia' : hours > 12 && hours <= 18 ? 'boa tarde' : hours > 18 && hours <= 23 ? 'boa noite' : ''

      const inputDay = form.children[4] as HTMLInputElement
      inputDay.value = day

      const inputMonth = form.children[5] as HTMLInputElement
      inputMonth.value = month

      const inputYear = form.children[6] as HTMLInputElement
      inputYear.value = year

      const inputHours = form.children[7] as HTMLInputElement
      inputHours.value = hoursConvert

      const inputMinutes = form.children[8] as HTMLInputElement
      inputMinutes.value = minutes

      emailjs.sendForm(
        `${process.env.REACT_APP_SERVICE_ID}`, `${process.env.REACT_APP_TEMPLATE_ID}`, form, `${process.env.REACT_APP_PUBLIC_KEY}`)
        .then(() => {
          if (timer) {
            clearTimeout(timer)
          }
          setLoading(false)
          setPopup(true)

          setTimer(setTimeout(() => { setPopup(false) }, 4000))
        }, () => {
          if (timer) {
            clearTimeout(timer)
          }
          setLoading(false)
          setPopupError(true)

          setTimer(setTimeout(() => { setPopupError(false) }, 4000))
        })

      setErros('')
      setName('')
      setEmail('')
      setPhone('')
      setOptionalText('')
      setSubmited(false)
    }
  }

  useEffect(() => {
    if (submited) {
      const valid = validBeforeSendForBackend(name, email, phone)
      setErros(valid.error)
    }
  }, [name, email, phone, submited])

  return (
    <S.Wrapper
      method='POST'
      onSubmit={handleSubmit}
    >
      <input
        value=''
        type='hidden'
        id='goodMessage'
        name='goodMessage'
      />

      <div className='wrapperInputAndLabel'>
        <label htmlFor="name">Nome</label>
        <input
          type='text'
          id='name'
          name='name'
          minLength={3}
          maxLength={55}
          value={name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setName(event.target.value)}
          placeholder='Digite seu Nome...'
          required
        />
        <p>{errors === 'name' && 'Nome inválido.'}</p>
      </div>

      <div className='wrapperInputAndLabel'>
        <label htmlFor="email">E-mail</label>
        <input
          type='text'
          id='email'
          name='email'
          minLength={11}
          maxLength={80}
          value={email}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value)}
          placeholder='Digite seu E-mail...'
          required
        />
        <p>{errors === 'email' && 'Email inválido.'}</p>
      </div>

      <div className='wrapperInputAndLabel'>
        <label htmlFor="phone">Telefone:</label>
        <input
          maxLength={14}
          minLength={13}
          value={phone}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPhone(maskTel(event.target.value))}
          type='text'
          id='phone'
          name='phone'
          placeholder='(99)99999-9999'
          required
        />
        <p>{errors === 'phone' && 'Telefone inválido.'}</p>
      </div>

      <input
        value=''
        type='hidden'
        id='day'
        name='day'
      />

      <input
        value={phone}
        type='hidden'
        id='month'
        name='month'
      />

      <input
        value=''
        type='hidden'
        id='year'
        name='year'
      />

      <input
        value={phone}
        type='hidden'
        id='hours'
        name='hours'
      />

      <input
        value={phone}
        type='hidden'
        id='minutes'
        name='minutes'
      />

      <div className='wrapperInputAndLabel'>
        <label htmlFor="optionalText">Mensagem</label>
        <textarea
          id='optionalText'
          name='optionalText'
          maxLength={400}
          placeholder='Digite aqui sua mensagem...'
          value={optionalText}
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => setOptionalText(event.target.value)}
        />
      </div>

      <div className='containerButton'>
        <button type='submit'>
            ENVIAR
        </button>
      </div>

      <div className='containerPopup'>
        {loading && (
          <div className='loading'></div>
        )}

        <S.PopUp popup={popup}>
          <GoVerified color={theme.colors.blue} size={width > 999 ? '3vw' : width > 599 && width < 1000 ? '4vw' : '9vw'} />

          <h4>Mensagem enviada com sucesso.</h4>
        </S.PopUp>
      </div>

      <div className='containerPopup'>
        <S.PopUp
          popup={popupError}
          typeError={true}
        >
          <MdOutlineError color={theme.colors.red} size={width > 999 ? '3vw' : width > 599 && width < 1000 ? '4vw' : '9vw'} />

          <h4>Ooppss... Erro ao enviar a mensagem.</h4>
        </S.PopUp>
      </div>
    </S.Wrapper>
  )
}
