import styled, { css } from 'styled-components'
import polygonGroup from '../../assets/png/polygonGroup.png'

type WrapperStyle = {
  scroll: boolean;
  mobile: boolean;
}

export const Wrapper = styled.section<WrapperStyle>`
  ${({ theme, scroll, mobile }) => css`
    width: 100vw;
    height: max-content;
    overflow: hidden;

    .Slide {
      margin: 0 auto;
      width: auto;
      display: flex;
      max-width: 1920px;
      overflow-y: hidden;
      overflow-x: auto;
      transition: height 0.1s;
      height: ${mobile && scroll ? '220vw' : 'max-content'};
      scrollbar-color: transparent;
      scrollbar-width: none;
      -ms-overflow-style: none;

      &::-webkit-scrollbar {
        display: none !important;
        width: 0;
        height: 0;
      }
      
      .wrapperPage {
        width: 1920px;
        max-width: 1920px;
        padding-top: 100px;
        display: flex;
        justify-content: center;
        height: max-content;

        @media (max-width: 3000px) {
          width: 100vw;
        }

        @media (min-width: 600px) and (max-width: 999.9px) {
          padding-top: 12vw;
        }

        @media (max-width: 599.9px) {
          padding-top: 100px;
        }

        &::-webkit-scrollbar {
          display: none;
        }
      }

      .wrapperPageWithBackground {
        width: 1920px;
        max-width: 1920px;
        padding-top: 100px;
        display: flex;
        justify-content: center;
        background-image: url(${polygonGroup});
        background-repeat: no-repeat;
        background-size: 70% 95%;
        background-position: 90% -5%;
        
        @media (max-width: 1920px) {
          width: 100vw;
        }

        @media (max-width: 599.9px) {
          padding-top: 100px;
          background-size: 140% 40%;
          background-position: 60% 100%;
        }

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  `}
`

export const PageOne = styled.div`
  width: 100vw;
  max-width: 100vw;
  height: max-content;

  .wrapper {
    margin: 0 auto;
    width: 75%;
    max-width: 100vw;
  }

  @media (max-width: 1920px) {
    width: 100vw;
  }

  @media (max-width: 999.9px) {
    .wrapper {
      width: 85%;
    }
  }

  @media (max-width: 599.9px) {
    height: 120vw;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`

export const WrapperShapesAndButton = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    padding-bottom: 70px;

    .hover {
      cursor: pointer;
    }

    .hover:hover {
      transform: scale(1.01, 1.01)
    }

    @media (min-width: 1921px) {
      .shapes {
        width: max-content;
        height: max-content;
        display: flex;

        .group1Polygon {
          width: max-content;
          height: max-content;
          display: flex;
        }

        .group2Polygon {
          width: max-content;
          height: max-content;
          display: flex;
        }
  
        .shapeSmallG1 {
          width: 179px;
          height: 159px;
          clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
          background-color: ${theme.colors.grayC4};
          margin-left: 100px;
          margin-top: -53px;
        }
  
        .shapeStrongerColor {
          width: 300px;
          height: 279px;
          clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
          background-color: ${theme.colors.blue};
          margin-left: -270px;
          margin-top: 130px;
          display: flex;
          align-items: center;
          
          h5 {   
            font-size: ${theme.font.sizes.small25};
            margin-left: 50px;
            width: 160px;
          }
        }

        .shapeSmallP1 {
          width: 99px;
          height: 85px;
          clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
          background-color: ${theme.colors.grayC4};
          margin-left: -150px;
          margin-top: 435px;
        }

        h3, h5, p {
          color: ${theme.colors.white};
        }

        h5 {
          font-size: ${theme.font.sizes.small25};
          line-height: 40px;
        }

        h3, p {
          margin-left: 55px;
        }

        .shapeG1 {
          width: 300px;
          height: 279px;
          clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
          background-color: ${theme.colors.blueLight};
          margin-left: 0px;
          margin-top: -23px;
          
          p {
            width: 170px;
          }
        }
        .shapeG1,
        .shapeG2,
        .shapeG3,
        .shapeG4,
        .shapeGPurple1,
        .shapeGPurple2,
        .shapeGPurple3,
        .shapeGPurple4 {
          display: flex;
          flex-direction: column;
          justify-content: center;

          h3 {
            color: ${theme.colors.white};
            font-size: 40px;
            line-height: 40px;
          }
  
          p {
            font-size: 20px;
            font-weight: ${theme.font.weight[600]};
            line-height: 30px;
          }

        }
        .shapeG2 {
          width: 300px;
          height: 279px;
          clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
          background-color: ${theme.colors.blueLight};
          margin-left: -300px;
          margin-top: 285px;

          p {
            width: 10vw;
          }
        }

        .shapeG3 {
          width: 300px;
          height: 279px;
          clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
          background-color: ${theme.colors.blueLight};
          margin-left: -55px;
          margin-top: -180px;
        }

        .shapeG4 {
          width: 300px;
          height: 279px;
          clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
          background-color: ${theme.colors.blueLight};
          margin-left: -300px;
          margin-top: 130px;
        }

        .shapeGPurple1 {
          width: 300px;
          height: 279px;
          clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
          background-color: ${theme.colors.purple};
          margin-left: -50px;
          margin-top: -23px;
          align-items: center;
        }

        .shapeGPurple2 {
          width: 300px;
          height: 279px;
          clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
          background-color: ${theme.colors.purple};
          margin-left: -300px;
          margin-top: 285px;
          align-items: center;
          text-align: center;
          
          h5 {
            width: 240px;
          }
        }

        .shapeGPurple3 {
          width: 300px;
          height: 279px;
          clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
          background-color: ${theme.colors.purple};
          margin-left: -50px;
          margin-top: 130px;
          align-items: center;

          h5 {
            width: 200px;
            text-align: center;
          }
        }

        .shapeGPurple4 {
          width: 300px;
          height: 279px;
          clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
          background-color: ${theme.colors.purple};
          margin-left: -300px;
          margin-top: 440px;
          align-items: center;

          h5 {
            width: 150px;
            text-align: center;
          }
        }

        .shapeSmallG2 {
          width: 179px;
          height: 159px;
          clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
          background-color: ${theme.colors.grayC4};
          margin-left: -460px;
          margin-top: 595px;
        }

        .shapeSmallG3 {
          width: 179px;
          height: 159px;
          clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
          background-color: ${theme.colors.grayC4};
          margin-left: 10px;
          margin-top: -60px;
        }

        .shapeSmallP2 {
          width: 99px;
          height: 85px;
          clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
          background-color: ${theme.colors.grayC4};
          margin-left: -25px;
          margin-top: -110px;
        }
      }
    }

    @media (max-width: 1920.9px) {
      .shapes {
        width: max-content;
        height: max-content;
        display: flex;

        .group1Polygon {
          width: max-content;
          height: max-content;
          display: flex;
        }

        .group2Polygon {
          width: max-content;
          height: max-content;
          display: flex;
        }
  
        .shapeSmallG1 {
          width: 10vw;
          height: 8.5vw;
          clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
          background-color: ${theme.colors.grayC4};
          margin-left: 5vw;
          margin-top: -2vw;
        }
  
        .shapeStrongerColor {
          width: 14vw;
          height: 12.5vw;
          clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
          background-color: ${theme.colors.blue};
          margin-left: -13vw;
          margin-top: 8vw;
          display: flex;
          align-items: center;
          
          h5 {   
            font-size: 1.3vw;
            margin-left: 1.8vw;
            width: 8vw;
          }
        }

        .shapeSmallP1 {
          width: 7vw;
          height: 5.8vw;
          clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
          background-color: ${theme.colors.grayC4};
          margin-left: -8.8vw;
          margin-top: 22vw;
        }

        h3, h5, p {
          color: ${theme.colors.white};
        }

        h5 {
          font-size: 1.3vw;
          line-height: 2vw;
        }

        h3, p {
          margin-left: 2.5vw
        }

        .shapeG1 {
          width: 14vw;
          height: 12.5vw;
          clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
          background-color: ${theme.colors.blueLight};
          margin-left: -0.5vw;
          margin-top: 1vw;
          
          p {
            width: 8vw;
          }
        }
        .shapeG1,
        .shapeG2,
        .shapeG3,
        .shapeG4,
        .shapeGPurple1,
        .shapeGPurple2,
        .shapeGPurple3,
        .shapeGPurple4 {
          display: flex;
          flex-direction: column;
          justify-content: center;

          h3 {
            color: ${theme.colors.white};
            font-size: 2vw;
            line-height: 2vw;
          }
  
          p {
            font-size: 1.05vw;
            font-weight: ${theme.font.weight[600]};
            line-height: 1.5vw;
          }

        }
        .shapeG2 {
          width: 14vw;
          height: 12.5vw;
          clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
          background-color: ${theme.colors.blueLight};
          margin-left: -14vw;
          margin-top: 15vw;

          p {
            width: 10vw;
          }
        }

        .shapeG3 {
          width: 14vw;
          height: 12.5vw;
          clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
          background-color: ${theme.colors.blueLight};
          margin-left: -2vw;
          margin-top: -6vw;
        }

        .shapeG4 {
          width: 14vw;
          height: 12.5vw;
          clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
          background-color: ${theme.colors.blueLight};
          margin-left: -14vw;
          margin-top: 8vw;
        }

        .shapeGPurple1 {
          width: 14vw;
          height: 12.5vw;
          clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
          background-color: ${theme.colors.purple};
          margin-left: -2vw;
          margin-top: 1vw;
          align-items: center;
        }

        .shapeGPurple2 {
          width: 14vw;
          height: 12.5vw;
          clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
          background-color: ${theme.colors.purple};
          margin-left: -14vw;
          margin-top: 15vw;
          align-items: center;
          text-align: center;
          
          h5 {
            width: 12vw;
          }
        }

        .shapeGPurple3 {
          width: 14vw;
          height: 12.5vw;
          clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
          background-color: ${theme.colors.purple};
          margin-left: -2vw;
          margin-top: 8vw;
          align-items: center;

          h5 {
            width: 7vw;
            text-align: center;
          }
        }

        .shapeGPurple4 {
          width: 14vw;
          height: 12.5vw;
          clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
          background-color: ${theme.colors.purple};
          margin-left: -14vw;
          margin-top: 22vw;
          align-items: center;

          h5 {
            width: 9vw;
            text-align: center;
          }
        }

        .shapeSmallG2 {
          width: 10vw;
          height: 8.5vw;
          clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
          background-color: ${theme.colors.grayC4};
          margin-left: -23vw;
          margin-top: 29vw;
        }

        .shapeSmallG3 {
          width: 10vw;
          height: 8.5vw;
          clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
          background-color: ${theme.colors.grayC4};
          margin-left: 0vw;
          margin-top: -2vw;
        }

        .shapeSmallP2 {
          width: 7vw;
          height: 5.8vw;
          clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
          background-color: ${theme.colors.grayC4};
          margin-left: -1vw;
          margin-top: -5vw;
        }
      }
    }

    @media (max-width: 999.9px) {
      padding-bottom: 7vw;
      .shapes {
        .shapeSmallG1 {
          width: 12vw;
          height: 10.5vw;
          margin-left: 4vw;
          margin-top: -4vw;
        }
  
        .shapeStrongerColor {
          width: 17vw;
          height: 15.5vw;
          margin-left: -15.6vw;
          margin-top: 8vw;
          
          h5 {   
            font-size: 1.6vw;
            margin-left: 2.3vw;
            width: 12vw;
          }
        }

        .shapeSmallP1 {
          width: 8.5vw;
          height: 7.3vw;
          margin-left: -10.7vw;
          margin-top: 25vw;
        }

        h5 {
          font-size: 1.6vw;
          line-height: 2.3vw;
        }

        h3, p {
          margin-left: 3.7vw
        }

        .shapeG1 {
          width: 17vw;
          height: 15.5vw;
          margin-left: -0.5vw;
          margin-top: -0.5vw;
          
          p {
            width: 12vw;
          }
        }
        .shapeG1,
        .shapeG2,
        .shapeG3,
        .shapeG4,
        .shapeGPurple1,
        .shapeGPurple2,
        .shapeGPurple3,
        .shapeGPurple4 {
          h3 {
            font-size: 2.3vw;
            line-height: 2.6vw;
          }
  
          p {
            font-size: 1.35vw;
            line-height: 1.8vw;
          }

        }
        .shapeG2 {
          width: 17vw;
          height: 15.5vw;
          margin-left: -17vw;
          margin-top: 16.7vw;

          p {
            width: 10vw;
          }
        }

        .shapeG3 {
          width: 17vw;
          height: 15.5vw;
          margin-left: -2.5vw;
          margin-top: -8.7vw;
        }

        .shapeG4 {
          width: 17vw;
          height: 15.5vw;
          margin-left: -17vw;
          margin-top: 8vw;
        }

        .shapeGPurple1 {
          width: 17vw;
          height: 15.5vw;
          margin-left: -2.5vw;
          margin-top: -0.5vw;
        }

        .shapeGPurple2 {
          width: 17vw;
          height: 15.5vw;
          margin-left: -17vw;
          margin-top: 16.7vw;
          
          h5 {
            width: 16vw;
          }
        }

        .shapeGPurple3 {
          width: 17vw;
          height: 15.5vw;
          margin-left: -2.5vw;
          margin-top: 8vw;

          h5 {
            width: 12vw;
          }
        }

        .shapeGPurple4 {
          width: 17vw;
          height: 15.5vw;
          margin-left: -17vw;
          margin-top: 25.2vw;

          h5 {
            width: 9vw;
          }
        }

        .shapeSmallG2 {
          width: 12vw;
          height: 10.5vw;
          margin-left: -28vw;
          margin-top: 34vw;
        }

        .shapeSmallG3 {
          width: 12vw;
          height: 10.5vw;
          margin-left: -0vw;
          margin-top: -4vw;
        }

        .shapeSmallP2 {
          width: 8.5vw;
          height: 7.3vw;
          margin-left: -1.5vw;
          margin-top: -8.5vw;
        }
      }
    }

    @media (max-width: 599.9px) {
      .shapes {
        flex-direction: column;

        .group1Polygon {
          margin: 0 auto;
          width: 85%;
          justify-content: center;
        }

        .group2Polygon {
          margin: 0 auto;
          width: 85%;
          margin-top: 2vw;
          justify-content: center;
        }
  
        .shapeSmallG1 {
          width: 15vw;
          height: 12vw;
          margin-left: 6vw;
          margin-top: 6vw;
        }
  
        .shapeStrongerColor {
          width: 35vw;
          height: 28.5vw;
          margin-left: -27.5vw;
          margin-top: 20vw;
          
          h5 {   
            font-size: 2.9vw;
            margin-left: 4.1vw;
            width: 20vw;
          }
        }

        .shapeSmallP1 {
          width: 10vw;
          height: 8.8vw;
          margin-left: -15vw;
          margin-top: 50.5vw;
        }

        h5 {
          font-size: 2.8vw;
          line-height: 4.5vw;
        }

        h3, p {
          margin-left: 6.3vw
        }

        .shapeG1 {
          width: 35vw;
          height: 28.5vw;
          margin-left: 0vw;
          margin-top: 4.5vw;
          
          p {
            width: 20vw;
          }
        }
        .shapeG1,
        .shapeG2,
        .shapeG3,
        .shapeG4,
        .shapeGPurple1,
        .shapeGPurple2,
        .shapeGPurple3,
        .shapeGPurple4 {
          h3 {
            font-size: 3.8vw;
            line-height: 3.8vw;
          }
  
          p {
            font-size: 2.5vw;
            line-height: 3.6vw;
          }

        }
        .shapeG2 {
          width: 35vw;
          height: 28.5vw;
          margin-left: -32.5vw;
          margin-top: 36vw;

          p {
            width: 20vw;
          }
        }

        .shapeG3 {
          width: 35vw;
          height: 28.5vw;
          margin-left: -5vw;
          margin-top: -11.5vw;
        }

        .shapeG4 {
          width: 35vw;
          height: 28.5vw;
          margin-left: -32.5vw;
          margin-top: 20vw;
        }

        .shapeGPurple1 {
          width: 35vw;
          height: 28.5vw;
          margin-left: -28vw;
          margin-top: 1vw;
        }

        .shapeGPurple2 {
          width: 35vw;
          height: 28.5vw;
          margin-left: -35vw;
          margin-top: 32.5vw;
          
          h5 {
            width: 24vw;
          }
        }

        .shapeGPurple3 {
          width: 35vw;
          height: 28.5vw;
          margin-left: -6vw;
          margin-top: 16.7vw;

          h5 {
            width: 20vw;
          }
        }

        .shapeGPurple4 {
          width: 35vw;
          height: 28.5vw;
          margin-left: -35vw;
          margin-top: 48.5vw;

          h5 {
            width: 20vw;
          }
        }

        .shapeSmallG2 {
          width: 15vw;
          height: 12vw;
          margin-left: -49vw;
          margin-top: 64vw;
        }

        .shapeSmallG3 {
          width: 15vw;
          height: 12vw;
          margin-left: 4vw;
          margin-top: 2vw;
        }

        .shapeSmallP2 {
          width: 10vw;
          height: 8.8vw;
          margin-left: -1vw;
          margin-top: -3vw;
        }
      }
    }
  `} 
`

export const WrapperButtonMoveNextOrBack = styled.div`
  ${({ theme }) => css`
  @media (min-width: 1920px) {
      width: max-content;
      display: flex;
      justify-content: flex-end;
      height: max-content;
      padding-bottom: 45px;
      margin-left: 70px;
      
      .button {
        width: 90px;
        height: 90px;
        border-radius: 50%;
        border: 3px solid ${theme.colors.gray};
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 640px;
        cursor: pointer;

        .borderPurple {
          width: 62px;
          height: 62px;
          border: 4px solid ${theme.colors.purple};
          background-color: transparent;
          border-radius: 50%;

          display: flex;
          align-items: center;
          justify-content: center;

          .centerPurple {
            width: 35px;
            height: 35px;
            background-color: ${theme.colors.purple};
            border-radius: 50%;
            
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }

    @media (max-width: 1920px) {
      width: max-content;
      display: flex;
      justify-content: flex-end;
      height: max-content;
      margin-left: 3.3vw;
      padding-bottom: 3vh;
      
      .button {
        width: 5vw;
        height: 5vw;
        border-radius: 50%;
        border: 0.2vw solid ${theme.colors.gray};
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30vw;
        cursor: pointer;

        .borderPurple {
          width: 3.75vw;
          height: 3.75vw;
          border: 0.25vw solid ${theme.colors.purple};
          background-color: transparent;
          border-radius: 50%;

          display: flex;
          align-items: center;
          justify-content: center;

          .centerPurple {
            width: 2.5vw;
            height: 2.5vw;
            background-color: ${theme.colors.purple};
            border-radius: 50%;
            
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }

    @media (max-width: 999.9px) {
      margin-left: -1vw;
      padding-bottom: 3vh;
      
      @media (min-width: 601) and (max-width> 799) {
        margin-left: -2vw;
      }
      .button {
        width: 8vw;
        height: 8vw;
        margin-top: 35vw;
        border: 0.45vw solid ${theme.colors.gray};

        .borderPurple {
          width: 6vw;
          height: 6vw;
          border: 0.55vw solid ${theme.colors.purple};

          .centerPurple {
            width: 4vw;
            height: 4vw;
          }
        }
      }
    }

    @media (max-width: 599.9px) {
      width: max-content;
      height: 110vw;
      padding-bottom: 3vh;
      margin-left: -30vw;
      
      .button {
        width: 13vw;
        height: 13vw;
        margin-top: 103vw;
        border: 0.6vw solid ${theme.colors.gray};

        .borderPurple {
          width: 10vw;
          height: 10vw;
          border: 0.8vw solid ${theme.colors.purple};

          .centerPurple {
            width: 7vw;
            height: 7vw;
          }
        }
      }
    }
  `}
`
