import styled, { css } from 'styled-components'

export const Wrapper = styled.form`
  ${({ theme }) => css`
    width: 50%;
    height: max-content;
    position: relative;

    .wrapperInputAndLabel {
      width: 100%;
      height: max-content;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      label {
        color: ${theme.colors.white};
        font-size: ${theme.font.sizes.small25};
        font-weight: ${theme.font.weight[700]};
        line-height: 55px;
      }

      input {
        width: 100%;
        height: 50px;
        background: #FFFFFF;
        border-radius: 10px;
        border: none;
        font-family: 'Nunito';
        outline: none;
        padding: 4px 13px;
        font-size: ${theme.font.sizes.small20};
      }

      textarea {
        width: 100%;
        height: 237px;
        background: #FFFFFF;
        border-radius: 10px;
        border: none;
        font-family: 'Nunito';
        outline: none;
        padding: 9px 13px;
        font-size: ${theme.font.sizes.small20};
        resize: none;
      }

      p {
        color: ${theme.colors.red};
        font-size: ${theme.font.sizes.small18};
        margin-top: 5px;
      }
    }

      .containerButton {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        height: max-content;
        margin-top: 49px;

        button {
          width: 194px;
          height: 45px;
          border: 3px solid ${theme.colors.blue};
          background-color: transparent;
          border-radius: 20px 0px 20px 20px;
          color: ${theme.colors.white};
          font-size: ${theme.font.sizes.small22};
          cursor: pointer;
          transition: transform 0.5s;

          &:hover {
            transform: scale3d(1.05, 1.05, 1);
          }
        }
      }

      .containerPopup {
        width: max-content;
        height: max-content;
        position: absolute;
        top: 35%;
        left: -100%;
      }

      .loading {
        display: inline-block;
        width: 80px;
        height: 80px;
        margin-left: 30vw;
      }
      .loading:after {
        content: " ";
        display: block;
        width: 64px;
        height: 64px;
        margin: 8px;
        border-radius: 50%;
        border: 6px solid #fff;
        border-color: #fff transparent #fff transparent;
        animation: lds-dual-ring 1.2s linear infinite;
      }
      @keyframes lds-dual-ring {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }


    .wrapperInputAndLabel:nth-child(n+2) {
      margin-top: 56px;
    }

    @media (min-width: 1650px) and (max-width: 1870.9px) {
      .wrapperInputAndLabel {
        label {
          font-size: 21px;
          line-height: 47px;
        }

        input {
          height: 42px;
          border-radius: 8px;
          padding: 3.4px 11px;
          font-size: 17px;
        }

        textarea {
          height: 203px;
          border-radius: 8px;
          padding: 3.4px 11px;
          font-size: 17px;
        }

        p {
          font-size: 15px;
          margin-top: 4.29px;
        }
      }

      .containerButton {
        button {
          width: 166px;
          height: 38px;
          border: 2.5px solid ${theme.colors.blue};
          border-radius: 17px 0px 17px 17px;
          font-size: 18px;
        }
      }

      .wrapperInputAndLabel:nth-child(n+2) {
        margin-top: 48px;
      }

      .wrapperInputAndLabel:last-child {
        margin-top: 42px;
      }
    }

    @media (min-width: 1440px) and (max-width: 1649.9px) {
      .wrapperInputAndLabel {
        label {
          font-size: 18px;
          line-height: 41px;
        }

        input {
          height: 37px;
          border-radius: 7.5px;
          padding: 3px 9.7px;
          font-size: 15px;
        }

        textarea {
          height: 177px;
          border-radius: 7.5px;
          padding: 3px 9.7px;
          font-size: 15px;
        }

        p {
          font-size: 13px;
          margin-top: 3.75px;
        }
      }

      .containerButton {
        button {
          width: 145px;
          height: 33px;
          border: 2.25px solid ${theme.colors.blue};
          border-radius: 15px 0px 15px 15px;
          font-size: 16px;
        }
      }

      .wrapperInputAndLabel:nth-child(n+2) {
        margin-top: 42px;
      }

      .wrapperInputAndLabel:last-child {
        margin-top: 36px;
      }
    }

    @media (min-width: 1200px) and (max-width: 1439.9px) {
      .wrapperInputAndLabel {
        label {
          font-size: 15px;
          line-height: 34px;
        }

        input {
          height: 31px;
          border-radius: 6.25px;
          padding: 2.5px 8.1px;
          font-size: 12.5px;
        }

        textarea {
          height: 148px;
          border-radius: 6.25px;
          padding: 2.5px 8.1px;
          font-size: 12.5px;
        }

        p {
          font-size: 11px;
          margin-top: 3px;
        }
      }

      .containerButton {
        button {
          width: 121px;
          height: 28px;
          border: 1.9px solid ${theme.colors.blue};
          border-radius: 12.5px 0px 12.5px 12.5px;
          font-size: 13px;
        }
      }

      .wrapperInputAndLabel:nth-child(n+2) {
        margin-top: 35px;
      }

      .wrapperInputAndLabel:last-child {
        margin-top: 30px;
      }
    }

    @media (min-width: 1000px) and (max-width: 1199.9px) {
      .wrapperInputAndLabel {
        label {
          font-size: 13px;
          line-height: 28px;
        }

        input {
          height: 26px;
          border-radius: 5.2px;
          padding: 2px 6.7px;
          font-size: 10px;
        }

        textarea {
          height: 123px;
          border-radius: 5.2px;
          padding: 2px 6.7px;
          font-size: 10px;
        }

        

        p {
          font-size: 9px;
          margin-top: 2.6px;
        }
      }

      .containerButton {
        button {
          width: 101px;
          height: 23px;
          border: 1.6px solid ${theme.colors.blue};
          border-radius: 10px 0px 10px 10px;
          font-size: 11px;
        }
      }

      .wrapperInputAndLabel:nth-child(n+2) {
        margin-top: 29px;
      }

      .wrapperInputAndLabel:last-child {
        margin-top: 25px;
      }
    }

    @media (min-width: 600px) and (max-width: 999.9px) {
      .wrapperInputAndLabel{
        label {
          font-size: 13px;
          line-height: 28px;
        }

        input {
          height: 26px;
          border-radius: 5.2px;
          padding: 2px 6.7px;
          font-size: 10px;
        }

        textarea {
          height: 123px;
          border-radius: 5.2px;
          padding: 2px 6.7px;
          font-size: 10px;
        }

        p {
          font-size: 9px;
          margin-top: 2.6px;
        }
      }

      .containerButton {
        button {
          width: 101px;
          height: 23px;
          border: 1.6px solid ${theme.colors.blue};
          border-radius: 10px 0px 10px 10px;
          font-size: 11px;
        }
      }

      .wrapperInputAndLabel:nth-child(n+2) {
        margin-top: 29px;
      }

      .wrapperInputAndLabel:last-child {
        margin-top: 25px;
      }
    }

    @media (max-width: 599.9px) {
      width: 100%;
      .wrapperInputAndLabel {
        label {
          font-size: 3.4vw;
          line-height: 7vw;
        }

        input {
          height: 6.5vw;
          border-radius: 5.2px;
          padding: 4vw 3vw;
          font-size: 3.2vw;
        }

        textarea {
          height: 42vw;
          border-radius: 5.2px;
          padding: 4vw 3vw;
          font-size: 3.2vw;
        }

        p {
          font-size: 2.7vw;
          margin-top: 2vw;
        }
      }

      .containerPopup {
        top: 10%;
        left: 16%;
      }

      .loading {
        width: 80px;
        height: 80px;
        margin-top: 7vw;
        margin-left: 21.5vw;
      }

      .containerButton {
        display: flex;
        align-items: center;
        justify-content: center;
        button {
          width: 50vw;
          height: 10vw;
          border: 0.5vw solid ${theme.colors.blue};
          border-radius: 20px 0px 20px 20px;
          font-size: 3.2vw;
        }
      }

      .wrapperInputAndLabel:nth-child(n+2) {
        margin-top: 5vw;
      }

      .wrapperInputAndLabel:last-child {
        margin-top: 7vw;
        align-items: center;
      }
    }
  `}
`

type PopStyle = {
  popup: boolean;
  typeError?: boolean;
}

export const PopUp = styled.div<PopStyle>`
  ${({ theme, popup, typeError }) => css`
    width: 25vw;
    height: 13vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${theme.colors.white};
    transition: opacity 0.5s;
    opacity: 0;
    visibility: hidden;
  
    animation-name: ${popup ? 'open' : ''};
    animation-duration: 5s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-delay: 0ms;
  
    @keyframes open {
      0% {
        visibility: visible;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0;
        visibility: hidden;
      }
    }
    border-radius: 0.4vw;
    border: ${typeError ? `0.1vw solid ${theme.colors.red}` : `0.1vw solid ${theme.colors.blue}`};
  
    h4 {
      font-size: 1.3vw;
      margin-top: 3vw;
      color: ${typeError ? theme.colors.red : theme.colors.blue}
    }
  
    @media (max-width: 999.9px) {
      width: 33vw;
      height: 15vw;
            
      svg {
        size: 5vw;
      }
  
      h4 {
        font-size: 1.7vw;
        margin-top: 3vw;
      }
    }
  
    @media (max-width: 599.9px) {
      width: 60vw;
      height: 45vw;
      border-radius: 1.2vw;
  
      h4 {
        font-size: 3.4vw;
        margin-top: 8vw;
      }
    }
  `}
`
