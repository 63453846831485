import styled, { css } from 'styled-components'

export const Wrapper = styled.section`
  ${({ theme }) => css`
    width: max-content;
    height: max-content;
    position: fixed;
    top: 85%;
    left: 89%;
    z-index: 101;

    &:hover {
      transform: scale(1.02, 1.02);
    }

    .relative {
      width: 167px;
      height: max-content;
      position: relative;
    }

    .FaleComAGente {
      width: 167px;
      height: 70px;
      background-color: ${theme.colors.blue};
      border-radius: 20px 0px 20px 20px;
      border: 3px solid ${theme.colors.white};
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    p {
      width: 98px;
      font-weight: 700;
      font-size: 23px;
      line-height: 22px;
      color: ${theme.colors.white};
      margin-left: 20px;
    }

    .IconWhatsApp {
      position: absolute;
      width: 55px;
      height: 55px;
      background: ${theme.colors.purple};
      border: 2px solid ${theme.colors.white};
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      top: -20%;
      left: 82%;
      cursor: pointer;
    }

    .relativeBorder {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .borderBlue {
      width: 85%;
      height: 85%;
      border: 2px solid ${theme.colors.blue};
      border-radius: 50%;
      position: absolute;
    }

    .centerWhite {
      width: 60%;
      height: 60%;
      border-radius: 50%;
      background-color: ${theme.colors.white};
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 16px;
        height: 16px;
      }
    }

    @media (min-width: 1650px) and (max-width: 1820.9px) {
      .relative {
        width: 143px;
        height: max-content;
      }

      .FaleComAGente {
        width: 143px;
        height: 60px;
        border-radius: 17px 0px 17px 17px;

        p {
          width: 84px;
          font-size: 19px;
          line-height: 18px;
          margin-left: 17px;
        }
      }

      .IconWhatsApp {
        width: 47px;
        height: 47px;
        border: 1.7px solid ${theme.colors.white};
      }

      .borderBlue {
        border: 1.7px solid ${theme.colors.blue};
      }

      .centerWhite {
        img {
          width: 14px;
          height: 14px;
        }
      }
    }

    @media (min-width: 1440px) and (max-width: 1649.9px) {
      .relative {
        width: 125px;
        height: max-content;
      }

      .FaleComAGente {
        width: 125px;
        height: 52px;
        border-radius: 15px 0px 15px 15px;
      }

      p {
        width: 73px;
        font-size: 17px;
        line-height: 16px;
        margin-left: 15px;
      }

      .IconWhatsApp {
        width: 41px;
        height: 41px;
        border: 1.5px solid ${theme.colors.white};
      }

      .borderBlue {
        border: 1.5px solid ${theme.colors.blue};
      }

      .centerWhite {
        img {
          width: 12px;
          height: 12px;
        }
      }
    }

    @media (min-width: 600px) and (max-width: 1439.9px) {
      .relative {
        width: 108px;
        height: 63px;
      }

      .FaleComAGente {
        width: 108px;
        height: 45px;
        border-radius: 13px 0px 13px 13px;
      }

      p {
        width: 63px;
        font-size: 14.8px;
        line-height: 14px;
        margin-left: 13px;
      }

      .IconWhatsApp {
        width: 35px;
        height: 35px;
        border: 1.3px solid ${theme.colors.white};
      }

      .borderBlue {
        border: 1.3px solid ${theme.colors.blue};
      }

      .centerWhite {
        img {
          width: 10px;
          height: 10px;
        }
      }
    }

    @media (max-width: 599.9px) {
      .relative {
        width: 140px;
        height: 61px;
      }

      .FaleComAGente {
        width: 140px;
        height: 66px;
        border-radius: 16px 0px 16px 16px;
      }

      p {
        width: 81px;
        font-size: 17px;
        line-height: 16px;
        margin-left: 15px;
      }

      .IconWhatsApp {
        width: 58px;
        height: 58px;
        border: 1.6px solid ${theme.colors.white};
      }

      .borderBlue {
        border: 1.6px solid ${theme.colors.blue};
      }

      .centerWhite {
        img {
          width: 14px;
          height: 14px;
        }
      }
    }

    @media (min-width: 780px) and (max-width: 999.9px) {
      top: 86%;
      left: 80vw;
    }

    @media (min-width: 750px) and (max-width: 779.9px) {
      top: 86%;
      left: 80vw;
    }

    @media (min-width: 600px) and (max-width: 749.9px) {
      top: 86%;
      left: 75vw;
    }

    @media (min-width: 560px) and (max-width: 599.9px) {
      top: 86%;
      left: 66vw;
    }

    @media (min-width: 470px) and (max-width: 559.9px) {
      top: 83%;
      left: 62vw;
    }

    @media (max-width: 469.9px) {
      top: 83%;
      left: 60vw;

      .relative {
        width: 30vw;
        height: 14vw;
      }

      .FaleComAGente {
        width: 30vw;
        height: 14.5vw;
        border-radius: 12px 0px 12px 12px;
      }

      p {
        width: 18vw;
        font-size: 3.9vw;
        line-height: 3.2vw;
        margin-left: 3.1vw;
      }

      .IconWhatsApp {
        width: 12vw;
        height: 12vw;
        border: 1.2px solid ${theme.colors.white};
      }

      .borderBlue {
        border: 1.2px solid ${theme.colors.blue};
      }

      .centerWhite {
        img {
          width: 4.5vw;
          height: 4.5vw;
        }
      }
    }
  `}
`
