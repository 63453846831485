import * as S from './styles'
import React from 'react'

import theme from '../../theme/theme'

import { TitleToComponent } from '../TitleToComponent/index'
import { IconWithBorders } from '../IconWithBorders/index'

type AboutServiceAndClientProps = {
  paddingTop: string;
  paddingLeft?: string;
  titleComponent: string;
  titleComponentBorderTopWidth: string;
  colorTitle: string;
  subTitle: string;
  marginTopWrapperTextAndIcon: string;
  textP: string;
  iconArrow: boolean;
  onClick?: () => void;
  textPTwo?: string;
  textPThree?: string;
  textPFour?: string;
  textPFive?: string;
  textPSix?: string;
  fontSizeP?: string;
  fontSizeH3?: string;
}

export const AboutServiceAndClient: React.FC<AboutServiceAndClientProps> = ({
  paddingTop,
  paddingLeft,
  titleComponent,
  titleComponentBorderTopWidth,
  colorTitle,
  subTitle,
  marginTopWrapperTextAndIcon,
  textP,
  iconArrow,
  onClick,
  textPTwo,
  textPThree,
  textPFour,
  textPFive,
  textPSix,
  fontSizeH3,
  fontSizeP
}) => {
  return (
    <S.Wrapper
      paddingTop={paddingTop}
      iconArrow={iconArrow}
      marginTopWrapperTextAndIcon={marginTopWrapperTextAndIcon}
      paddingLeft={paddingLeft}
      fontSizeH3={fontSizeH3}
      fontSizeP={fontSizeP}
    >
      <div className='paddingLeft'>
        <TitleToComponent
          titleToComponent={titleComponent}
          borderTopWidth={titleComponentBorderTopWidth}
          colorTitle={colorTitle}
        />
      </div>

      <h3>{subTitle}</h3>

      <div className="wrapperTextAndIcon">
        <div>
          <p>{textP}</p>

          <p>{textPTwo}</p>

          <p>{textPThree}</p>

          <p>{textPFour}</p>

          <p>{textPFive}</p>

          <p>{textPSix}</p>
        </div>

        {
          iconArrow && (
            <div className="wrapperIcon">
              <IconWithBorders
                firstBorderWidthAndHeight='100.5px'
                firstBorderSize="2px"
                secoundBorderWidthAndHeight='80px'
                secoundBorderSize="4px"
                centerWidthAndHeight='50px'
                ioioArrowForward={true}
                sizeIcon='20.25px'
                colorSecoundaryBorder={theme.colors.purple}
                marginTop='0px'
                cursorIoIoArrowForward='pointer'
                onClick={onClick}
              />
            </div>
          )
        }
      </div>
    </S.Wrapper>
  )
}
