import React, { useContext } from 'react'
import * as S from './styles'

import { GlobalContext } from '../../context/GlobalContext'

export const CookieComponentRef: React.FC = () => {
  const {
    cookieComponentRef,
    setOpenPoliticaPrivacidade,
    setOpenCookiesModal
  } = useContext(GlobalContext)

  function apresentarCookie () {
    setOpenCookiesModal(false)
    localStorage.setItem('acceptedCookies', 'true')
  }

  return (
    <S.Wrapper ref={cookieComponentRef}>
      <div className="cookie-notice">
        <div className="paragrafos">
          <p><strong>Esse site usa Cookies.</strong> Nós armazenamos dados temporariamente para melhorar a sua experiência de navegação e recomendar conteúdo de seu interesse</p>
          <br />
        </div>
        <div className="botoes">
          <button className="botao-politica" onClick={() => setOpenPoliticaPrivacidade(true)}>Política de Privacidade</button>
          <button onClick={apresentarCookie} className="botao-aceitar">Aceitar Todos</button>
        </div>
      </div>
    </S.Wrapper>
  )
}

export default CookieComponentRef
