import * as S from './styles'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { TitleToComponent } from '../TitleToComponent'
import theme from '../../theme/theme'
import { IoIosArrowForward } from 'react-icons/io'
import { GlobalContext } from '../../context/GlobalContext'
import { NextPageComponentOne } from './NextPageComponent/index'

export const ServiceComponent: React.FC = () => {
  const { servicesComponentRef, width, mobile } = useContext(GlobalContext)
  const [scroll, setScroll] = useState(true)
  const secoundComponentSlide = useRef<HTMLDivElement>(null)

  const moveNextPageOrBackPage = (page: number) => {
    if (servicesComponentRef.current && width < 1920 && secoundComponentSlide.current) {
      if (secoundComponentSlide.current.offsetLeft > 1920) {
        servicesComponentRef.current.scroll({
          left: 1920 * page,
          behavior: 'smooth'
        })
      } else {
        servicesComponentRef.current.scroll({
          left: secoundComponentSlide.current.offsetLeft * page,
          behavior: 'smooth'
        })
      }
    }

    if (servicesComponentRef.current && width >= 1920) {
      console.log(secoundComponentSlide?.current?.offsetLeft)
      servicesComponentRef.current.scroll({
        left: 1920 * page,
        behavior: 'smooth'
      })
    }
  }

  useEffect(() => {
    if (servicesComponentRef) {
      servicesComponentRef.current?.addEventListener('scroll', () => {
        if (servicesComponentRef.current !== null &&
          (servicesComponentRef.current?.scrollLeft < width || servicesComponentRef.current?.scrollLeft === 0)) {
          setScroll(true)
        } else {
          setScroll(false)
        }
      })
    }
  }, [servicesComponentRef])

  const liText = [
    'Possibilita que você se dedique às principais atividades do seu negócio.',
    'Reduz custos e encargo de contratação.',
    'Sua empresa tem acesso a profissionais especialistas de alta performance.',
    'Garante a entrega das melhores práticas de TI.',
    'Maior quantidade de técnicos à sua disposição.'
  ]

  const liText2 = [
    'Aumentar a produtividade, assertividade e qualidade nas entregas dos projetos.',
    'Transparência na gestão e comunicação da evolução dos projetos.',
    'Maior alinhamento e co-responsabilidade de todas as áreas envolvidas nos projetos.',
    'Padronização dos processos e artefatos de apoio a todos os Scrum Master/Gerentes de Projetos.'
  ]

  return (
    <S.Wrapper
      scroll={scroll}
      mobile={mobile}
    >
      <div className='Slide' ref={servicesComponentRef}>
        <div className='wrapperPage'>
          <S.PageOne>
            <div className='wrapper'>
              <TitleToComponent
                borderTopWidth='55%'
                colorTitle={theme.colors.blue}
                titleToComponent='Serviços'
                borderTopColor={theme.colors.purple}
              />

              <S.WrapperShapesAndButton>
                <div className='shapes'>
                  <div className='group1Polygon'>
                    <div className='shapeSmallG1'></div>

                    <div
                      className='shapeStrongerColor hover'
                      onClick={() => moveNextPageOrBackPage(1)}
                    >
                      <h5>Desenvolvimento<br></br> de Soluções:</h5>
                    </div>

                    <div className='shapeSmallP1'></div>

                    <div className='shapeG1'>
                      <h3>01.</h3>

                      <p>Aplicativos Mobile(Tablet/<br></br>Celular) e WEB</p>
                    </div>

                    <div className='shapeG2'>
                      <h3>02.</h3>

                      <p>Software de<br></br> Gestão e Backoffice</p>
                    </div>

                    <div className='shapeG3'>
                      <h3>03.</h3>

                      <p>Arquitetura de<br></br> Sistemas de TI</p>
                    </div>

                    <div className='shapeG4'>
                      <h3>04.</h3>

                      <p>Plataforma<br></br> Tecnológica</p>
                    </div>
                  </div>

                  <div className='group2Polygon'>
                    <div
                      className='shapeGPurple1 hover'
                      onClick={() => moveNextPageOrBackPage(2)}
                    >
                      <h5>Outsourcing</h5>
                    </div>

                    <div
                      className='shapeGPurple2 hover'
                      onClick={() => moveNextPageOrBackPage(3)}
                    >
                      <h5>Consultoria em Gestão Estratégica em TI</h5>
                    </div>

                    <div
                      className='shapeGPurple3 hover'
                      onClick={() => moveNextPageOrBackPage(4)}
                    >
                      <h5>Gestão<br></br> de Projetos</h5>
                    </div>

                    <div
                      className='shapeGPurple4 hover'
                      onClick={() => moveNextPageOrBackPage(5)}
                    >
                      <h5>Escritório de Projetos PMO</h5>
                    </div>

                    <div className='shapeSmallG2'></div>

                    <div className='shapeSmallG3'></div>

                    <div className='shapeSmallP2'></div>
                  </div>

                </div>

                <S.WrapperButtonMoveNextOrBack>
                  <div className='button' onClick={() => moveNextPageOrBackPage(1)}>
                    <div className='borderPurple'>
                      <div className='centerPurple'>
                        <IoIosArrowForward color={'white'} size={'18px'} />
                      </div>
                    </div>
                  </div>
                </S.WrapperButtonMoveNextOrBack>
              </S.WrapperShapesAndButton>
            </div>
          </S.PageOne>
        </div>

        <div className='wrapperPageWithBackground' ref={secoundComponentSlide}>
          <NextPageComponentOne
            moveBackPage={() => moveNextPageOrBackPage(0)}
            moveNextPage={() => moveNextPageOrBackPage(2)}
            title='Desenvolvimento de soluções'
            borderTitleWidth={mobile ? '30%' : '15%'}
            h4Text='Montamos uma Squad multidisciplinar que atuará desde o entendimento da sua
            necessidade até a implantação e sustentação da solução desenvolvida.'
            h4Width='43vw'
            p1Text='Trabalhamos com diversos frameworks'
            p2Text='de desenvolvimento tanto tablet/celular quanto web.'
            pHtml={mobile ? 'Trabalhamos com diversos<br></br>frameworks de desenvolvimento<br></br>tanto tablet/celular quanto web.' : ''}
            pWidth='40vw'
            marginTopMobileFirstP='14vw'
            pLineHeightMobile='3.3vw'
            marginTopButtons='16.2vw'
            marginTopButtonsInPx='365px'
            marginTopTabletFormat='16vw'
            h4WidthTabletFormat='62vw'
            pWidthTabletFormat='51vw'
            widthTextResponsive='45vw'
            h4WidthMobileFormat='76vw'
            pWidthMobileFormat='79vw'
            marginTopMobileButton='110vw'
          />
        </div>

        <div className='wrapperPageWithBackground'>
          <NextPageComponentOne
            moveBackPage={() => moveNextPageOrBackPage(1)}
            moveNextPage={() => moveNextPageOrBackPage(3)}
            title='Outsourcing'
            borderTitleWidth='35%'
            h4Text='O Outsourcing é a terceirização dos serviços de TI, podem ser desde serviços
            de tecnologia ou até o setor inteiro, ou seja, seremos responsáveis pela gestão da TI,
            controle e manutenção dos sistemas de informação e fornecimento de profissionais especializados.'
            h4Width='52vw'
            p1Text='Benefícios:'
            liText={liText}
            pWidth='52.4vw'
            paragraph={true}
            marginTopButtons='4.6vw'
            marginTopButtonsInPx='85px'
            marginTopTabletFormat='3vw'
            h4WidthTabletFormat='81vw'
            pWidthTabletFormat='56vw'
            h4WidthMobileFormat='79vw'
            liWidthMobileFormat='74vw'
            marginTopMobileButton='20vw'
          />
        </div>

        <div className='wrapperPageWithBackground'>
          <NextPageComponentOne
            moveBackPage={() => moveNextPageOrBackPage(2)}
            moveNextPage={() => moveNextPageOrBackPage(4)}
            title='Consultoria em Gestão Estratégica em TI'
            textHtml={mobile ? 'Consultoria em<br></br> Gestão Estratégica em TI' : ''}
            borderTitleWidth={mobile ? '18%' : '12%'}
            h4Text='A Gestão Estratégica de TI é um conjunto de práticas para gerir os recursos
            e ferramentas da área, com o objetivo de otimizar os processos e resultados.'
            h4Width='47vw'
            p1Text='É um serviço que realizamos em empresas que já possuem um setor de TI
            estruturado e precisam otimizar a gestão da área.'
            p2Text='A gestão de TI é sustentada por 3 pilares: pessoas, processos e tecnologia.
            As pessoas participam do processo de tratamento da informação; os processos estruturam
            a área de TI; já a tecnologia dá suporte aos processos.'
            paragraph={true}
            pWidth='45.5vw'
            marginTopButtons='2vw'
            marginTopButtonsInPx='110px'
            marginTopTabletFormat=''
            h4WidthTabletFormat='68vw'
            pWidthTabletFormat='67vw'
            widthTextResponsive='85vw'
            h4WidthMobileFormat='77vw'
            pWidthMobileFormat='69vw'
            marginTopMobileButton='60vw'
            lineHeigthMobile='5vw'
          />
        </div>

        <div className='wrapperPageWithBackground'>
          <NextPageComponentOne
            moveBackPage={() => moveNextPageOrBackPage(3)}
            moveNextPage={() => moveNextPageOrBackPage(5)}
            title='Gestão de Projetos'
            borderTitleWidth='23%'
            h4Text='A Gestão de Projetos é um conjunto de estratégias e procedimentos
            para gerenciar times durante a concepção de um projeto interno, garantindo
            que o desenvolvimento das demandas seja assertivo de acordo com o que foi
            planejado, das métricas estabelecidas para medir o desempenho, dentro do
            cronograma e prazos de entrega propostos.'
            h4Width='50vw'
            p1Text='Benefícios:'
            liText={liText2}
            pWidth='52.4vw'
            paragraph={true}
            marginTopButtons='4vw'
            marginTopButtonsInPx='75px'
            marginTopTabletFormat='3vw'
            h4WidthTabletFormat='77vw'
            pWidthTabletFormat='70vw'
            h4WidthMobileFormat='76vw'
            liWidthMobileFormat='63vw'
            marginTopMobileButton='5vw'
          />
        </div>

        <div className='wrapperPageWithBackground'>
          <NextPageComponentOne
            moveBackPage={() => moveNextPageOrBackPage(4)}
            moveNextPage={() => moveNextPageOrBackPage(0)}
            title='PMO - Escritório de Gerenciamento de Projetos'
            borderTitleWidth={mobile ? '20%' : '15%'}
            h4Text='Project Management Officer (PMO), é a estrutura organizacional criada para
            atender a demanda das empresas em relação a coordenação da gestão de projetos.'
            h4Width='53vw'
            p1Text='A principal atribuição do PMO é criar e definir as metodologias e indicadores que
            devem ser utilizados pelos Scrum Master/Gerentes de Projetos, sendo uma posição estratégica e consultiva.'
            p2Text='Instruir os Scrum Master/Gerentes de Projetos a usarem as melhores práticas
            servindo como um consultor. Acompanhar a execução e andamento dos projetos
            posicionando o corpo diretivo da empresa.'
            pWidth='52.4vw'
            paragraph={true}
            marginTopButtons='6.9vw'
            marginTopButtonsInPx='170px'
            marginTopTabletFormat='3vw'
            h4WidthTabletFormat='77vw'
            pWidthTabletFormat='77vw'
            widthTextResponsive='65vw'
            h4WidthMobileFormat='78vw'
            pWidthMobileFormat='70vw'
            marginTopMobileButton='40vw'
          />
        </div>
      </div>
    </S.Wrapper>
  )
}
