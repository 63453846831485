import * as S from './styles'
import React, { useContext, useEffect, useRef, useState } from 'react'
import theme from '../../theme/theme'

import { TitleToComponent } from '../TitleToComponent/index'
import { IconWithBorders } from '../IconWithBorders'
import { GlobalContext } from '../../context/GlobalContext'

import pratiImage from '../../assets/webP/praTi.webp'
import sinosserraFinanceiraImage from '../../assets/webP/sinosserraFinanceira.webp'
import sinosserraImage from '../../assets/webP/sinosserra.webp'

export const ProjectComponent: React.FC = () => {
  const {
    projectsComponentRef,
    setOurProjectComponent,
    setControllerScroll,
    moveComponentProject,
    mobile,
    width
  } = useContext(GlobalContext)

  const [moveScroll, setMoveScroll] = useState('1')
  const scrollBarWidth = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (projectsComponentRef.current && scrollBarWidth.current) {
      scrollBarWidth.current?.addEventListener('scroll', () => {
        if (scrollBarWidth.current?.scrollLeft && scrollBarWidth.current?.scrollLeft < width * 0.8) {
          setMoveScroll('1')
        }

        if (scrollBarWidth.current?.scrollLeft &&
          scrollBarWidth.current?.scrollLeft > width * 0.8 &&
          scrollBarWidth.current?.scrollLeft < width * 1.6) {
          setMoveScroll('2')
        }

        if (scrollBarWidth.current?.scrollLeft && scrollBarWidth.current?.scrollLeft > width * 1.6) {
          setMoveScroll('3')
        }
      })
    }
  }, [scrollBarWidth])

  function moveToOurProjectComponent () {
    window.scroll({
      top: 0,
      behavior: 'smooth'
    })

    setOurProjectComponent(true)
    setControllerScroll(true)
  }

  return (
    <S.Wrapper ref={projectsComponentRef} moveScrollForBall={moveScroll}>
      <div className='wrapperWithMaxWidth'>
        <div className='centralized'>
          <div className="containerTitle">
            <TitleToComponent
              borderTopWidth='55%'
              colorTitle={theme.colors.white}
              borderTopColor={theme.colors.blue}
              titleToComponent='Projetos'
            />
          </div>
        </div>

        {mobile && (
          <div className='containerBalls'>

            <div className='ball1'><span className='center'></span></div>
            <div className='ball2'><span className='center'></span></div>
            <div className='ball3'><span className='center'></span></div>

          </div>
        )}

          <div className='centralizedGrid'>
            <div className="gridTable" ref={scrollBarWidth}>
              <div className='slider'>
                  <div
                    className="containerWithImage adjustingMarginLeft"
                  >
                    <div
                      className="wrapperImage" style={{ backgroundImage: `url(${sinosserraFinanceiraImage})` }}
                      onClick={() => moveComponentProject(1)}
                    >
                      <IconWithBorders
                        secoundBorderWidthAndHeight='60px'
                        centerWidthAndHeight='38px'
                        bsPlusLg={true}
                        firstBorderWidthAndHeight='80px'
                        firstBorderSize='2px'
                        secoundBorderSize='4px'
                        colorSecoundaryBorder={theme.colors.blue}
                        sizeIcon='10.22px'
                        cursorBsPlusLg='pointer'
                        marginTop='0px'
                      />
                    </div>

                    <h5>Sinosserra Financeira</h5>

                    <p>Design e desenvolvimento mobile.</p>
                  </div>

                  <div
                    className="containerWithImage"
                  >
                    <div
                      className="wrapperImage" style={{ backgroundImage: `url(${pratiImage})` }}
                      onClick={() => moveComponentProject(2)}
                    >
                      <IconWithBorders
                        secoundBorderWidthAndHeight='60px'
                        centerWidthAndHeight='38px'
                        bsPlusLg={true}
                        firstBorderWidthAndHeight='80px'
                        firstBorderSize='2px'
                        secoundBorderSize='4px'
                        colorSecoundaryBorder={theme.colors.blue}
                        sizeIcon='10.22px'
                        cursorBsPlusLg='pointer'
                        marginTop='0px'
                      />
                    </div>

                    <h5>+PraTi</h5>

                    <p>Design e desenvolvimento mobile.</p>
                  </div>

                  <div
                    className="containerWithImage adjustingMarginRight"
                  >
                    <div
                      className="wrapperImage" style={{ backgroundImage: `url(${sinosserraImage})` }}
                      onClick={() => moveComponentProject(3)}
                    >
                      <IconWithBorders
                        secoundBorderWidthAndHeight='60px'
                        centerWidthAndHeight='38px'
                        bsPlusLg={true}
                        firstBorderWidthAndHeight='80px'
                        firstBorderSize='2px'
                        secoundBorderSize='4px'
                        colorSecoundaryBorder={theme.colors.blue}
                        sizeIcon='10.22px'
                        cursorBsPlusLg='pointer'
                        marginTop='0px'
                      />
                    </div>

                    <h5>Sinosserra</h5>

                    <p>Gestão, Design e desenvolvimento.</p>
                  </div>
                </div>
              </div>

              <div className="buttonViewAllProject">
                <button onClick={moveToOurProjectComponent}>
                  VER TODOS OS PROJETOS
                </button>
              </div>
          </div>
      </div>
    </S.Wrapper>
  )
}
